import { createAction } from 'typesafe-actions';
import { bidPlacementActions as actionTypes } from '../constants';
import { stringUtils, formatUtils, biddingUtils } from '../utils';

const setBidCanSubmit = createAction(actionTypes.SET_BID_CAN_SUBMIT, resolve => positionId => resolve({ positionId }));
const storeCanSubmitPositionFlag = createAction(actionTypes.BID_PLACEMENT_SET_BID_CAN_SUBMIT,
    resolve => (positionId, canSubmit) => resolve({ positionId, canSubmit })
);
const revert = createAction(actionTypes.REVERT, resolve => positionId => resolve({ positionId }));
const revertAll = createAction(actionTypes.REVERT_ALL);
const commissionChangeAction = createAction(actionTypes.COMMISSION_CHANGE,
    resolve => (positionId, commission, error) => resolve({ positionId, commission, error })
);

export const bidPlacementActions = {
    bidChange,
    axedChange,
    finalChange,
    passChange,
    revert,
    revertAll,
    validateSingle,
    validate,
    formatBid,
    setBidCanSubmit,
    storeCanSubmitPositionFlag,
    commissionChange
}

function bidChange(positionId, bid) {
    return (dispatch, getState) => {
        const editBid = getState().brokerDealerBidding.editBid[positionId];

        dispatch({
            type: actionTypes.BID_PLACEMENT_BID_CHANGE,
            positionId,
            bid
        });

        if (stringUtils.isNullOrWhiteSpace(bid)) {
            dispatch(setBidErrors([{ positionId, errors: {} }]));
        } else if (editBid && editBid.errors) {
            dispatch(validateSingle(positionId));
        }

        dispatch(setBidCanSubmit(positionId));
    };
}

function formatBid(positionId) {
    return (dispatch, getState) => {
        const position = getState().brokerDealerBidding.securities.find(s => s.id === positionId);
        const edit = getState().brokerDealerBidding.editBid[positionId];

        if (!position || !edit || edit.pass || edit.value == null) return;

        dispatch({
            type: actionTypes.BID_PLACEMENT_BID_CHANGE,
            positionId,
            bid: edit.value ? formatUtils.formatBid(edit.value) : undefined
        });

        dispatch(validateSingle(positionId));
        dispatch(setBidCanSubmit(positionId));
    };
}

function axedChange(positionId, axed) {
    return dispatch => {
        dispatch({
            type: actionTypes.BID_PLACEMENT_AXED_CHANGE,
            positionId,
            axed
        });

        dispatch(setBidCanSubmit(positionId));
    };
}

function finalChange(positionId, final) {
    return dispatch => {
        dispatch({
            type: actionTypes.BID_PLACEMENT_FINAL_CHANGE,
            positionId,
            final
        });

        dispatch(setBidCanSubmit(positionId));
    };
}

function passChange(positionId, pass) {
    return dispatch => {
        dispatch({
            type: actionTypes.BID_PLACEMENT_PASS_CHANGE,
            positionId,
            pass
        });

        dispatch(setBidCanSubmit(positionId));
    };
}

function validateSingle(positionId) {
    return (dispatch, getState) => {
        const { securities, editBid } = getState().brokerDealerBidding;
        const { process } = getState().bidding.bwic;
        const position = securities.find(s => s.id === positionId);

        if (position != null) {
            const currentBid = biddingUtils.getCurrentBid(position.bids);
            const errors = biddingUtils.validateBid(
                currentBid,
                editBid[positionId],
                process,
                position.openBiddingStage2Level
            );
            dispatch(setBidErrors([{ positionId, errors }]));
        }
    };
}

function validate() {
    return (dispatch, getState) => {
        const { securities, editBid } = getState().brokerDealerBidding;
        const { process } = getState().bidding.bwic;

        const validationResult = securities
            .filter(s => editBid[s.id] && editBid[s.id].canSubmit)
            .map(s => {
                const currentBid = biddingUtils.getCurrentBid(s.bids);
                const errors = biddingUtils.validateBid(
                    currentBid,
                    editBid[s.id],
                    process,
                    s.openBiddingStage2Level
                );

                return {
                    positionId: s.id,
                    errors
                };
            });

        dispatch(setBidErrors(validationResult));
    };
}

function setBidErrors(errors) {
    return {
        type: actionTypes.BID_PLACEMENT_SET_BID_ERRORS,
        errors
    };
}

function commissionChange(positionId, commission, error){
    return dispatch => {
        dispatch(commissionChangeAction(positionId, commission, error));
        dispatch(setBidCanSubmit(positionId));
    }
}