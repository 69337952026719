import { ActionType, createAction } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { ArrangerPipeline } from '../types/amr-pipeline/models/ArrangerPipeline';
import { Deal } from '../types/amr-pipeline/models/Deal';
import { ArrangerPipelineStatistic } from '../types/amr-pipeline/models/ArrangerPipelineStatistic';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';

const initFilter = createAction('amrArrangerPipeline/INIT_FILTER');

const applyFilterAndSearch = createAction('amrArrangerPipeline/APPLY_FILTER_AND_SEARCH');

const resetFilter = createAction('amrArrangerPipeline/RESET_FILTER');

const arrangerPipelinesRequest = createAction('amrArrangerPipeline/REQUEST');

const resetPipelines = createAction('amrArrangerPipeline/RESET_PIPELINES');

const dealsRequest = createAction(
    'amrArrangerPipeline/DEALS_REQUEST',
    resolve => (searchTerm: string) => resolve({ searchTerm }),
);
const dealsResponse = createAction(
    'amrArrangerPipeline/DEALS_RESPONSE',
    resolve => (requestState: RequestState, deals?: Deal[]) => resolve({ requestState, deals }),
);

const dealsSearchResponse = createAction(
    'amrArrangerPipeline/DEALS_SEARCH_RESPONSE',
    resolve => (requestState: RequestState, arrangerPipelineDeals?: Partial<Deal>[]) =>
        resolve({ requestState, arrangerPipelineDeals }),
);

const selectDealsFromSearch = createAction(
    'amrArrangerPipeline/SELECT_DEALS_FROM_SEARCH',
    resolve => (dealsLegalNames: string[]) => resolve({ dealsLegalNames }),
);

const resetDeals = createAction('amrArrangerPipeline/RESET_DEALS');

const arrangerPipelinesResponse = createAction(
    'amrArrangerPipeline/RESPONSE',
    resolve => (requestState: RequestState, arrangerPipelines?: ArrangerPipeline[], nonVisibleCount?: number) =>
        resolve({ requestState, arrangerPipelines, nonVisibleCount }),
);

const submitArrangerPipeline = createAction('amrArrangerPipeline/CREATE');
const sortingChange = createAction(
    'amrArrangerPipeline/SORTING_CHANGE',
    resolve => (sortBy: string, sortDirection: string) => resolve({ sortBy, sortDirection }),
);

const edit = createAction('amrArrangerPipeline/EDIT');

const setEditLoadingStatus = createAction(
    'amrArrangerPipeline/SET_EDIT_LOADING_STATUS',
    resolve => (isLoading: boolean) => resolve({ isLoading }),
);

const submitArrangerPipelineStatus = createAction(
    'amrArrangerPipeline/SUBMIT_STATUS',
    resolve => (isSubmitted: boolean) => resolve({ isSubmitted }),
);

const changeDealReferenceName = createAction(
    'amrArrangerPipeline/EDIT/CHANGE_DEAL_REFERENCE_NAME',
    resolve => (dealReferenceName: string | null) => resolve({ dealReferenceName }),
);

const changeDealLegalName = createAction(
    'amrArrangerPipeline/EDIT/CHANGE_DEAL_LEGAL_NAME',
    resolve => (dealLegalName: string | null) => resolve({ dealLegalName }),
);

const updateFields = createAction(
    'amrArrangerPipeline/EDIT/UPDATE_FIELDS',
    resolve => (collateralManagerReferenceName: string, collateralType: CollateralType) =>
        resolve({ collateralManagerReferenceName, collateralType }),
);

const setAnyPipelineExists = createAction(
    'amrArrangerPipeline/SET_ANY_PIPELINE_EXISTS',
    resolve => (anyPipelineExists: boolean) => resolve({ anyPipelineExists }),
);

const changeFilterSelectFromTable = createAction(
    'amrArrangerPipeline/CHANGE_FILTER_SELECT_FROM_TABLE',
    resolve => (referenceName: string, filterName: string) => resolve({ referenceName, filterName }),
);

const checkIfArrangerPipelineExist = createAction('amrArrangerPipeline/CHECK_IF_ARRANGER_PIPELINE_EXIST');

const exportPipelines = createAction('amrArrangerPipeline/EXPORT');

const exportLoad = createAction(
    'amrArrangerPipeline/EXPORT_LOAD',
    resolve => (isLoading: boolean) => resolve({ isLoading }),
);

const reset = createAction('amrArrangerPipeline/RESET');

const arrangerPipelineStatisticRequest = createAction('amrArrangerPipeline/STATISTIC_REQUEST');
const arrangerPipelineStatisticResponse = createAction(
    'amrArrangerPipeline/STATISTIC_RESPONSE',
    resolve => (summary: ArrangerPipelineStatistic) => resolve({ summary }),
);

export type TArrangerPipelineActions = ActionType<
    | typeof applyFilterAndSearch
    | typeof resetFilter
    | typeof arrangerPipelinesRequest
    | typeof arrangerPipelinesResponse
    | typeof reset
    | typeof sortingChange
    | typeof resetPipelines
    | typeof submitArrangerPipeline
    | typeof exportPipelines
    | typeof dealsRequest
    | typeof dealsResponse
    | typeof resetDeals
    | typeof submitArrangerPipelineStatus
    | typeof exportLoad
    | typeof setAnyPipelineExists
    | typeof changeDealLegalName
    | typeof changeFilterSelectFromTable
    | typeof dealsSearchResponse
    | typeof selectDealsFromSearch
    | typeof edit
    | typeof setEditLoadingStatus
    | typeof arrangerPipelineStatisticRequest
    | typeof arrangerPipelineStatisticResponse
>;

export const amrArrangerPipelineActions = {
    reset,
    resetFilter,
    applyFilterAndSearch,
    arrangerPipelinesRequest,
    arrangerPipelinesResponse,
    submitArrangerPipeline,
    resetPipelines,
    sortingChange,
    initFilter,
    exportPipelines,
    edit,
    setEditLoadingStatus,
    dealsRequest,
    dealsResponse,
    resetDeals,
    submitArrangerPipelineStatus,
    changeDealReferenceName,
    changeDealLegalName,
    exportLoad,
    setAnyPipelineExists,
    changeFilterSelectFromTable,
    dealsSearchResponse,
    selectDealsFromSearch,
    checkIfArrangerPipelineExist,
    arrangerPipelineStatisticRequest,
    arrangerPipelineStatisticResponse,
    updateFields,
};
