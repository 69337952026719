import { createAction } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { InventoryPositionStatus } from '../types/inventory/InventoryPositionStatus';

const loadBdInventory = createAction('bd-inventory/LOAD_BD_INVENTORY');

const request = createAction('entities/bd-inventory/REQUEST');
const requestResult = createAction(
    'entities/bd-inventory/REQUEST_RESULT',
    resolve => (requestState: RequestState, response: { inventorySecurities: InventoryPosition[], lockModifiedDate: Date } | {}) =>
        resolve({ requestState, response })
);
const inventoryStatusChange = createAction(
    'entities/bd-inventory/INVENTORY_STATUS_CHANGE',
    resolve => (inventoryPositionId: number, status: InventoryPositionStatus) => resolve({ inventoryPositionId, status })
);
const init = createAction('bd-inventory/INIT');
const requestInventoryExistFlag = createAction('bd-inventory/INVENTORY_EXIST_REQUEST');
const inventoryExistFlag = createAction(
    'bd-inventory/INVENTORY_EXIST_FLAG',
    resolve => (exist: boolean) => resolve({ exist })
);
const pushInventory = createAction(
    'entities/bd-inventory/PUSH_INVENTORY',
    resolve => (data: { lockModifiedDate: Date, updatedInventorySecurities: InventoryPosition[] }) => resolve({ data })
);

const resetEntities = createAction('entities/bd-inventory/RESET');
const reset = createAction('bd-inventory/RESET');
const exportInventoryRequest = createAction(
    'bd-inventory/EXPORT_INVENTORY_REQUEST',
    resolve => (sortField: string, sortDirection: string) => resolve({ sortField, sortDirection })
);
const exportInventoryResponse = createAction('bd-inventory/EXPORT_INVENTORY_RESPONSE');
const requestSendEmail = createAction(
    'bd-inventory/SEND_EMAIL',
    resolve => (params: { selectedDealerEmail: boolean, selectedBloombergEmail: boolean }) => resolve(params)
);
const toggleVisibleAddBloombergEmailForm = createAction('bd-inventory/TOGGLE_VISIBLE_ADD_BLOOMBERG_EMAIL_FORM');
const addBloombergEmailRequest = createAction(
    'bd-inventory/ADD_BLOOMBERG_EMAIL_REQUEST',
    resolve => (email: string) => resolve({ email })
);
const addBloombergEmailResponse = createAction('bd-inventory/ADD_BLOOMBERG_EMAIL_RESPONSE');

const applyFilter = createAction('bd-inventory/FILTER_APPLY');
const clearFilter = createAction('bd-inventory/FILTER_CLEAR');
const advancedFilterBlocked = createAction('bd-inventory/ADVANCED_FILTER_BLOCKED', resolve => (blocked: boolean) => resolve({ blocked }));

export const bdInventoryActions = {
    init,
    requestInventoryExistFlag,
    inventoryExistFlag,
    loadBdInventory,
    request,
    resetEntities,
    requestResult,
    inventoryStatusChange,
    pushInventory,
    applyFilter,
    clearFilter,
    reset,
    exportInventoryRequest,
    exportInventoryResponse,
    requestSendEmail,
    toggleVisibleAddBloombergEmailForm,
    addBloombergEmailRequest,
    addBloombergEmailResponse,
    advancedFilterBlocked
};
