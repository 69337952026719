import { createAction } from "typesafe-actions";
import { BidOnBehalfConflict } from "../types/bid-on-behalf/BidOnBehalfConflict";
import { CompanySlim } from "../types/company/CompanySlim";

const requestCompanies = createAction('bidOnBehalf/REQUEST');
const setCompainesRequesting = createAction(
    'bidOnBehalf/REQUESTING',
    resolve => (isRequesting: boolean) => resolve({ isRequesting })
);
const reset = createAction('bidOnBehalf/RESET');
const storeCompanies = createAction(
    'bidOnBehalf/STORE',
    resolve => (companies: CompanySlim[]) => resolve({ companies })
);
const editBid = createAction(
    'bidOnBehalf/EDIT_BID',
    resolve => (positionId: number, companyId: number, value: string, editCompleted: boolean, error?: string) =>
        resolve({ positionId, companyId, value, editCompleted, error })
);
const resetBidEdit = createAction(
    'bidOnBehalf/RESET_BID_EDIT',
    resolve => (positionId: number, companyId: number) => resolve({ positionId, companyId })
);
const resetCompanyBidsEdit = createAction(
    'bidOnBehalf/RESET_COMPANY_BIDS_EDIT',
    resolve => (companyId: number) => resolve({ companyId })
);
const resetEdit = createAction('bidOnBehalf/RESET_EDIT');
const pass = createAction(
    'bidOnBehalf/PASS',
    resolve => (positionId: number, companyId: number) => resolve({ positionId, companyId })
);
const axed = createAction(
    'bidOnBehalf/AXED',
    resolve => (positionId: number, companyId: number, axed: boolean) => resolve({ positionId, companyId, axed })
);
const final = createAction(
    'bidOnBehalf/FINAL',
    resolve => (positionId: number, companyId: number, final: boolean) => resolve({ positionId, companyId, final })
);
const submit = createAction(
    'bidOnBehalf/SUBMIT',
    resolve => (resolveConflicts?: boolean) => resolve({ resolveConflicts })
);
const submitProgress = createAction('bidOnBehalf/SUBMIT_POGRESS',
    resolve => (inProgress: boolean) => resolve({ inProgress })
);
const storeConflicts = createAction(
    'bidOnBehalf/CONFLICTS',
    resolve => (conflicts: BidOnBehalfConflict[]) => resolve({ conflicts })
);
const resetConflicts = createAction('bidOnBehalf/RESET_CONFLICTS');
const conflictsPopupVisible = createAction(
    'bidOnBehalf/CONFLICTS_POPUP_VISIBLE',
    resolve => (visible: boolean) => resolve({ visible })
);
const moveNextBid = createAction('bidOnBehalf/MOVE_NEXT_BID',
    resolve => (companyId: number, positionId: number) => resolve({ companyId, positionId })
);
const setNextBidIdentifiers = createAction('bidOnBehalf/SET_NEXT_BID_IDENTIFIERS',
    resolve => (companyId: number, positionId: number) => resolve({ companyId, positionId })
);
const resetNextBidIdentifiers = createAction('bidOnBehalf/RESET_NEXT_BID_IDENTIFIERS');

export const bidOnBehalfActions = {
    requestCompanies,
    setCompainesRequesting,
    reset,
    storeCompanies,
    editBid,
    resetEdit,
    resetBidEdit,
    resetCompanyBidsEdit,
    pass,
    axed,
    final,
    submit,
    submitProgress,
    storeConflicts,
    resetConflicts,
    conflictsPopupVisible,
    moveNextBid,
    setNextBidIdentifiers,
    resetNextBidIdentifiers
}
