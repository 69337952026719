import { createAction } from 'typesafe-actions';
import { Company } from '../types/amr-pipeline/models/Company';

const loadUserCompanyRequest = createAction('amrPipelineCommon/userCompany/REQUEST');
const loadUserCompanyResponse = createAction(
    'amrPipelineCommon/userCompany/RESPONSE',
    resolve => (userCompany: Company) => resolve({ userCompany })
);

const loadUserCompanyError = createAction('amrPipelineCommon/userCompany/ERROR');

const registerUser = createAction('amrPipelineCommon/REGISTER_USER');

const redirectInvitedClientToTransactionDetails = createAction(
    'amrPipelineCommon/REDIRECT_INVITED_CLIENT'
);

const reset = createAction('amrPipelineCommon/RESET');

export const amrPipelineCommonActions = {
    loadUserCompanyRequest,
    loadUserCompanyResponse,
    loadUserCompanyError,
    registerUser,
    redirectInvitedClientToTransactionDetails,
    reset
};
