import { createAction } from 'typesafe-actions';
import { SubmitResultState } from '../types/state/BrokerDealerBiddingState';

const confirmation = createAction('bidConfirmation/SHOW',
    resolve => (positionIdList: number[]) => resolve({ positionIdList })
);
const submitInProgress = createAction('bidConfirmation/SUBMIT_IN_PROGRESS',
    resolve => (inProgress: boolean, positionId?: number) => resolve({ inProgress, positionId })
);
const reset = createAction('bidConfirmation/RESET');
const submitResults = createAction('bidConfirmation/SUBMIT_RESULTS',
    resolve => (result: { [positionId: number]: SubmitResultState }) => resolve({ result }));

export const bidConfirmationActions = {
    confirmation,
    submitInProgress,
    reset,
    submitResults
}
