import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { autoFeedbackSettingsActions as actionTypes } from '../constants';
import { AutoFeedbackSettings } from '../types/models/AutoFeedbackSettings';
import { blastMessageService, bwicService } from '../services';
import { errorActions } from './error.actions';
import { controlPanelActions } from './control-panel.actions';
import { TradeStatus } from '../types/trades/TradeStatus';
import { getLatestStage1Bids, getSecuritiesWithLatestBids } from '../selectors';
import { biddingUtils, numericUtils } from '../utils';
import { BwicPosition } from '../types/bwic/BwicPosition';
import { Bid } from '../types/bidding/Bid';
import { feedbackTypes } from '../constants/bidding';
import { AppState } from '../types/state/AppState';
import { Bwic } from '../types/bwic/Bwic';
import { CompanySlim } from '../types/company/CompanySlim';
import { BlastMessage } from '../types/blast-message/BlastMessage';
import { BwicProcessType } from '../types/models/Process';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { BidLevel } from '../types/bidding/BidLevel';
import moment from 'moment';
import dateTimeUtils from '../utils/dateTime.utils';
import { calculateStage2Participants } from '../utils/stage2-participants-calculator';
import { BidRequestType } from '../types/bidding/BidRequest';

export const autoFeedbackSettingsActions = {
    showAutoFeedbackSettingsPanel,
    toggleAutoFeedbackSettingsPanel,
    toggleAutofeedback
}

type TDispatch = ThunkDispatch<any, void, AnyAction>;
type TAction = ThunkAction<void, AppState, any, AnyAction>;

function toggleAutofeedback() {
    return (dispatch: TDispatch, getState: () => any): void => {
        const { bwic } = getState().bidding;
        const settings: AutoFeedbackSettings = bwic.autoFeedbackConfiguration;

        const autoFeedbackSettings = {
            onPlatform: !settings.onPlatform,
            offPlatform: !settings.offPlatform
        };

        dispatch(saveAutoFeedbackSettings(bwic.referenceName, autoFeedbackSettings));
    };
}

function saveAutoFeedbackSettings(
    bwicReferenceName: string,
    settings: AutoFeedbackSettings
): TAction {
    return (dispatch: TDispatch, getState: () => AppState): void => {
        const bwic: Bwic = getState().bidding.bwic;

        if (bwic) {
            dispatch(saving(true));

            bwicService
                .saveAutoFeedbackConfiguration(bwicReferenceName, settings)
                .then(saved)
                .catch(() => errorActions.unexpectedError())
                .finally(() => dispatch(saving(false)));
        }

        function saved() {
            dispatch(storeSettings(settings));

            const isTopXStage2 =
                bwic.process.type === BwicProcessType.TopX &&
                bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;

            if (settings.onPlatform && settings.onPlatform) {

                const securities = getState().sellerBidding.securities
                    .filter(s => s.trade == null || s.trade.status === TradeStatus.rejected);

                const blastMessage: BlastMessage = { companyMessages: [] };

                securities.forEach((s: BwicPosition) => {
                    if (isTopXStage2) {
                        addTopXStage2BlastMessageItems(blastMessage, s, bwic);
                    } else {
                        addBlastMessageItems(blastMessage, s, bwic.companies)
                    }
                });

                let shouldSendBlastMessage = true;
                if (bwic.process.autoFeedbackDelay) {
                    const autoFeedbackDelayMinutes = dateTimeUtils.parseTimeSpan(bwic.process.autoFeedbackDelay).totalMinutes;
                    if (autoFeedbackDelayMinutes) {
                        const bwicBidsDueDatePlusAutoFeedbackDelay = moment
                            .utc(bwic.bidsDueUtc)
                            .add(autoFeedbackDelayMinutes, 'minutes')
                        shouldSendBlastMessage = moment().isSameOrAfter(bwicBidsDueDatePlusAutoFeedbackDelay);
                    }
                }

                if (shouldSendBlastMessage && blastMessage.companyMessages.some(m => m.positionMessages.length > 0)) {
                    dispatch(sendBlastMessage(bwic.referenceName, blastMessage));
                }
            }
        }
    };
}

function addTopXStage2BlastMessageItems(message: BlastMessage, security: BwicPosition, bwic: Bwic) {
    const [{ bids }] = getSecuritiesWithLatestBids({ securities: [security] }); // Take latest bids to build actual top rate
    const bidsByLevel = biddingUtils.groupBidsByLevel(bids);

    // Send feedack to Top [x] participaants
    bidsByLevel
        .slice(0, bwic.process.topX!.improverCount) // Take top x
        .forEach((group, index) => {
            const level = index as BidLevel;
            let feedback: number | undefined;
            switch (level) {
                case BidLevel.Best: feedback = group.length === 1 ? feedbackTypes.best : feedbackTypes.tiedForBest; break;
                case BidLevel.Cover: feedback = feedbackTypes.cover; break;
                case BidLevel.Third: feedback = feedbackTypes.third; break;
                case BidLevel.Fourth: feedback = feedbackTypes.fourth; break;
                case BidLevel.Fifth: feedback = feedbackTypes.fifth; break;
                default: feedback = undefined;
            }

            if (feedback != null) {
                group.forEach(b => addBlastMessageItem(message, security.id, b.company.id, b, feedback));
            }
        });

    // Send 'Not in top [x]' to participants who passed to stage 2 but out of top [x]
    const stage2Participants = calculateStage2Participants(getLatestStage1Bids(security.bids ?? []), bwic.process);
    bidsByLevel
        .slice(bwic.process.topX!.improverCount, bidsByLevel.length) // Out of top x
        .forEach(group =>
            group.forEach(b => {
                if (stage2Participants[biddingUtils.getBidCompanyIdentifier(b)]) {
                    addBlastMessageItem(message, security.id, b.company.id, b, feedbackTypes.outOfTop3);
                }
            })
        )
}

function addBlastMessageItems(message: BlastMessage, security: BwicPosition, participants: CompanySlim[]) {
    const [{ bids }] = getSecuritiesWithLatestBids({ securities: [security] }); // Take latest bids
    const [best, cover, third, ...notInTop3] = biddingUtils.groupBidsByLevel(bids);
    const hasManyBestBids = best?.length > 1;

    (best || []).forEach(b => addBlastMessageItem(
        message,
        security.id,
        b.company.id,
        b,
        hasManyBestBids ? feedbackTypes.tiedForBest : feedbackTypes.best));

    cover && cover.forEach(b => addBlastMessageItem(message, security.id, b.company.id, b, feedbackTypes.cover));
    third && third.forEach(b => addBlastMessageItem(message, security.id, b.company.id, b, feedbackTypes.third));

    if (notInTop3 && notInTop3.length) {
        notInTop3.flat().forEach(b => addBlastMessageItem(message, security.id, b.company.id, b, feedbackTypes.outOfTop3));
    }

    // Send 'bid or pass' to participants without bids
    participants
        .filter(p => !security.bids?.some(b => biddingUtils.getBidCompanyIdentifier(b) === String(p.id)))
        .forEach(p => addBlastMessageItem(message, security.id, p.id, undefined, undefined, BidRequestType.BidOrPass));
}

function addBlastMessageItem(
    message: BlastMessage,
    positionId: number,
    brokerDealerCompanyId: number,
    bid?: Bid,
    feedbackType?: number,
    bidRequestType?: number) {
    let companyMessages = message.companyMessages.find(c =>
        c.brokerDealerCompanyId === brokerDealerCompanyId &&
        numericUtils.numberOrDefault(c.buyerPseudoOrderNumber) === numericUtils.numberOrDefault(bid?.buyerPseudoOrderNumber)
    );

    if (!companyMessages) {
        companyMessages = {
            brokerDealerCompanyId,
            buyerPseudoOrderNumber: bid?.buyerPseudoOrderNumber,
            positionMessages: []
        };

        message.companyMessages.push(companyMessages);
    }

    companyMessages.positionMessages.push({
        positionId,
        feedbackBidId: bid?.id,
        feedbackType,
        bidRequestType
    });
}

function sendBlastMessage(bwicReferenceName: string, message: BlastMessage) {
    return async (dispatch: TDispatch) => {
        dispatch(blastMessageSendingState(true));

        try {
            await blastMessageService.send(bwicReferenceName, message);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        } finally {
            dispatch(blastMessageSendingState(false));
        }
    }
}

function blastMessageSendingState(isSending: boolean) {
    return {
        type: actionTypes.AUTO_FEEDBACK_BLAST_MESSAGE_SENDING,
        isSending
    };
}

function saving(isSaving: boolean): AnyAction {
    return {
        type: actionTypes.AUTO_FEEDBACK_SETTINGS_SAVING,
        isSaving
    };
}

function storeSettings(settings: AutoFeedbackSettings): AnyAction {
    return {
        type: actionTypes.AUTO_FEEDBACK_SETTINGS_STORE,
        settings
    };
}

function toggleAutoFeedbackSettingsPanel(): TAction {
    return (dispatch: TDispatch, getState: () => any) => {
        const visible: boolean = getState().sellerBidding.autoFeedbackSettingsVisible;

        dispatch(showAutoFeedbackSettingsPanel(!visible));
    };
}

function showAutoFeedbackSettingsPanel(visible: boolean): TAction {
    return (dispatch: TDispatch, getState: () => AppState) => {
        if (visible) {
            dispatch(controlPanelActions.hide());
        }

        const { autoFeedbackSettingsVisible } = getState().sellerBidding;
        if (autoFeedbackSettingsVisible !== visible) {
            dispatch({
                type: actionTypes.AUTO_FEEDBACK_SETTINGS_PANEL_VISIBLE,
                visible
            });
        }
    };
}
