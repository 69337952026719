import { createAction } from 'typesafe-actions';
import { AutoCompleteSourceItem } from '../components/amrPipeline/types/AutoCompleteInputSourceItem';
import { RequestState } from '../constants/request-state';
import { Contact } from '../types/amr-pipeline/models/Contact';
import { PipelineHistoryItem } from '../types/state/PipelineHistoryState';

interface TransactionDetails {
    syndicateContacts: Contact[];
}

const transactionHistoryRequest = createAction(
    'amrPipelineTransactionHistory/REQUEST',
    resolve => (dealReferenceName: string, transactionReferenceName: string) => resolve({ dealReferenceName, transactionReferenceName })
);

const transactionHistoryResponse = createAction(
    'amrPipelineTransactionHistory/RESPONSE',
    resolve => (requestState: RequestState, transactionsHistory?: PipelineHistoryItem[], allHistoryClassesList?: AutoCompleteSourceItem[]) => resolve({ requestState, transactionsHistory, allHistoryClassesList })
);

const loadTransactionDetais = createAction(
    'amrPipelineTransactionHistory/loadTransactionDetais',
    resolve => (version: number, dealReferenceName: string, transactionReferenceName: string, companyReferenceName?: string) =>
        resolve({ version, dealReferenceName, transactionReferenceName, companyReferenceName })
);

const storeTransactionDetais = createAction(
    'amrPipelineTransactionHistory/storeTransactionDetais',
    resolve => (version: number, transactionDetails: TransactionDetails) =>
        resolve({ version, transactionDetails })
);

const reset = createAction(
    'amrPipelineTransactionHistory/RESET',
    resolve => () => resolve()
);

const transactionFieldsUpdatedRequest = createAction(
    'amrPipelineTransactionFieldsUpdated/REQUEST',
    resolve => (dealReferenceName: string, transactionReferenceName: string, classReferenceName?: string,) =>
        resolve({ dealReferenceName, transactionReferenceName, classReferenceName })
);

const transactionFieldsUpdatedResponse = createAction(
    'amrPipelineTransactionFieldsUpdated/RESPONSE',
    resolve => (
        requestState: RequestState,
        lastUpdatedFields?: {
            guidance?: Date;
            subscription?: Date;
        },
    ) => resolve({ requestState, lastUpdatedFields })
);

const transactionFieldsUpdatedReset = createAction('amrPipelineTransactionFieldsUpdated/RESET');

export const amrTransactionHistoryActions = {
    transactionHistoryRequest,
    transactionHistoryResponse,
    loadTransactionDetais,
    storeTransactionDetais,
    reset,
    transactionFieldsUpdatedRequest,
    transactionFieldsUpdatedResponse,
    transactionFieldsUpdatedReset,
};
