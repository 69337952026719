import { biddingActions as actionTypes, roles, routes } from '../constants';
import { bwicService, companiesService } from '../services';
import {
    errorActions,
    sellerBiddingActions,
    brokerDealerBiddingActions,
    otherSellerBiddingActions,
    rulesActions,
    bwicHistoryActions
} from '.';
import { user } from '../user';
import { controlPanelActions } from './control-panel.actions';
import { RequestState } from '../constants/request-state';
import { BwicVisitorType } from '../types/state/BiddingState';
import { BwicStatus } from '../types/enums/BwicStatus';
import { history } from '../history';


export const biddingActions = {
    init,
    reset,
    bwicStatusChange,
    loadBwicParticipants,
    liveBiddingStage2TimeoutEnd,
    loading
};

function init(referenceName) {
    return async dispatch => {
        dispatch(loading(true));

        try {
            const bwicPromise = bwicService.getByReferenceName(referenceName);
            const userCompanyPromise = companiesService.getCurrentUserCompany();

            const userCompany = await userCompanyPromise;
            const bwic = await bwicPromise;

            const visitorType = getBiddingVisitorType(bwic, userCompany);

            dispatch({
                type: actionTypes.BIDDING_STORE_VISITOR_TYPE,
                visitorType,
                userCompany
            });

            dispatch(bwicHistoryActions.init(bwic.referenceName, bwic.actionHistory));
            dispatch(storeOriginalBwic(bwic));
            await dispatch(initVisitorState(visitorType, bwic));

        } catch (e) {
            dispatch(errorActions.criticalError(e));
        } finally {
            dispatch(loading(false));
        }
    };
}

function bwicStatusChange(bwicReferenceName, nextStatus) {
    return (dispatch, getState) => {
        const { bwic, visitorType } = getState().bidding;

        const isBwicMatch = bwic && bwic.referenceName === bwicReferenceName;
        if (!isBwicMatch) return;

        const isScheduled = bwic.status === BwicStatus.scheduled;
        const isNextBidding = nextStatus === BwicStatus.bidding;
        const isAllToAllBuyer = bwic.isAllToAll && visitorType === BwicVisitorType.Buyer;
        if (isScheduled && isNextBidding && isAllToAllBuyer) {
            // Change bwic status in store for all-to-all bidding bwic on buyer screen
            return dispatch({
                type: actionTypes.BIDDING_BWIC_STATUS_CHANGE,
                nextStatus
            });
        }

        dispatch(reset());
        dispatch(init(bwicReferenceName));
    };
}

function initVisitorState(visitorType, bwic) {
    return dispatch => {
        switch (visitorType) {
            case (BwicVisitorType.Seller):
                return dispatch(sellerBiddingActions.init(bwic));
            case (BwicVisitorType.OtherSeller):
                return dispatch(otherSellerBiddingActions.init(bwic));
            case (BwicVisitorType.BrokerDealer):
            case (BwicVisitorType.Buyer):
                return dispatch(brokerDealerBiddingActions.init(bwic));
            case (BwicVisitorType.Admin):
                return;
            default:
                return history.push(routes.forbidden);
        }
    };
}

function loading(isLoading) {
    return {
        type: actionTypes.BIDDING_LOADING,
        isLoading
    };
}

function storeOriginalBwic(bwic) {
    if (bwic.companies) {
        bwic.companies.sort((a, b) => a.name.localeCompare(b.name));
    }

    return {
        type: actionTypes.BIDDING_STORE_ORIGINAL_BWIC,
        bwic
    };
}

function getBiddingVisitorType(bwic, userCompany) {
    let visitorType;
    if (user.hasRoles(...roles.seller())) {
        visitorType = !bwic.isParsed && bwic.seller?.id === userCompany.id
            ? BwicVisitorType.Seller
            : BwicVisitorType.OtherSeller;
    } else if (user.hasRoles(...roles.bd())) {
        if (bwic.seller?.id === userCompany.id) return BwicVisitorType.Seller
        return bwic.isAllToAll ? BwicVisitorType.Buyer : BwicVisitorType.BrokerDealer;
    } else if (user.hasRoles(roles.Administrator)) {
        visitorType = BwicVisitorType.Admin
    }

    return visitorType;
}

function reset() {
    return dispatch => {
        dispatch(rulesActions.hide());
        dispatch({ type: actionTypes.BIDDING_RESET });
        dispatch(controlPanelActions.hide());
    };
}

function loadBwicParticipants() {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;
        if (bwic) {
            dispatch(setLoadParticipantsRequestStatus(RequestState.request));

            companiesService
                .getBwicParticipants(bwic.referenceName)
                .then(dealers => {
                    dispatch(storeBwicDealers(dealers));
                    dispatch(setLoadParticipantsRequestStatus(RequestState.success));
                })
                .catch(e => {
                    dispatch(setLoadParticipantsRequestStatus(RequestState.failure));
                    dispatch(errorActions.criticalError(e));
                });
        }
    };
}

function setLoadParticipantsRequestStatus(requestState) {
    return {
        type: actionTypes.BIDDING_LOAD_PARTICIPANTS_REQUEST_STATUS,
        payload: { requestState }
    };
}

function storeBwicDealers(bwicDealers) {
    return dispatch => {
        const bwicDealerUsers = [];
        const bwicDealerContacts = [];
        bwicDealers.forEach(dealer => {
            (dealer.users || []).forEach(user => {
                bwicDealerUsers.push({ ...user, companyId: dealer.id });
            });
            (dealer.contacts || []).forEach(contact => {
                bwicDealerContacts.push({ ...contact, companyId: dealer.id })
            })
        });

        dispatch({
            type: actionTypes.STORE_BWIC_PARTICIPANTS,
            payload: { bwicDealers, bwicDealerUsers, bwicDealerContacts }
        })
    };
}

function liveBiddingStage2TimeoutEnd() {
    return { type: actionTypes.LIVE_BIDDING_STAGE_2_TIMEOUT_END };
}

