import { createAction } from 'typesafe-actions';
import { City } from '../types/location/City';
import { Country } from '../types/location/Country';
import { State } from '../types/location/State';

const loadCountries = createAction('address/countries/REQUEST');

const loadCountriesResponse = createAction(
    'address/countries/RESPONSE',
    resolve => (countries: Country[]) => resolve(countries),
);

const loadStates = createAction(
    'address/states/REQUEST',
    resolve => (countryId: number) => resolve({ countryId }),
);

const loadStatesResponse = createAction(
    'address/states/RESPONSE',
    resolve => (states: State[]) => resolve(states),
);

const loadCities = createAction(
    'address/cities/REQUEST',
    resolve => (countryId: number, stateId?: number) => resolve({ countryId, stateId }),
);

const loadCitiesResponse = createAction(
    'address/cities/RESPONSE',
    resolve => (cities: City[]) => resolve(cities),
);

export const addressActions = {
    loadCountries,
    loadCountriesResponse,
    loadStates,
    loadStatesResponse,
    loadCities,
    loadCitiesResponse,
};
