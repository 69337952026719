import { createAction } from "typesafe-actions";

const requestActiveInventorySecurities = createAction("entities/active-inventories-securities/REQUEST");

const requestActiveInventorySecuritiesSuccess = createAction('entities/active-inventories-securities/REQUEST_SUCCESS',
    resolve => (securityIds: number[]) => resolve({ securityIds }))

const requestActiveInventorySecuritiesError = createAction("entities/active-inventories-securities/REQUEST_ERROR");

const reset = createAction("entities/active-inventories-securities/RESET");

export const activeInventorySecurityActions = {
    requestActiveInventorySecurities,
    requestActiveInventorySecuritiesSuccess,
    requestActiveInventorySecuritiesError,
    reset
}
