import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { entries, values } from "lodash";
import { history } from '../history';
import { AppState } from "../types/state/AppState";
import { GridColumn, GridDataItem, GridUploadStatus } from '../types/state/GridState';
import { errorActions } from './error.actions';
import { gridActions } from './grid.actions';
import { errorMessages, constants, createCompanyBobColumn } from '../constants';
import { bidOnBehalfService } from '../services/bid-on-behalf.service';
import { BidOnBehalfFileUploadResultItem } from "../types/bid-on-behalf/BidOnBehalfFileUploadResultItem";
import { arrayUtils } from "../utils/array.utils";
import { BidOnBehalfParseResultItem } from "../types/state/BidOnBehalfState";
import { apiUtils } from "../utils/api.utils";
import { notificationActions } from "./notification.actions";
import { dealerListPanelActions } from "./dealer-list-panel.actions";
import { bidOnBehalfActions } from "./bid-on-behalf.actions";
import { formatUtils } from "../utils/format.utils";
import { LocationStateBuilder } from "../types/state/ui/LocationState";
import { getSecuritiesWithLatestBids } from "../selectors";
import { BwicPosition } from "../types/bwic/BwicPosition";
import { Bid } from "../types/bidding/Bid";
import { bidOnBehalfUtils } from "../utils";
import { biddingUtils } from "../utils";

export const bidOnBehalfParseActions = {
    uploadFile,
    parseClipboard,
    addCompany,
    deleteItemsWithErrors,
    removeUnknownCompanyColumns,
    saveBids
}

type TDispatch = ThunkDispatch<AppState, void, AnyAction>;

function uploadFile(file: File) {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const { position, upload, mountedKey, headers, dataItems } = getState().grid;
        const { process } = getState().bidding.bwic;

        if (upload.status === GridUploadStatus.Uploading) return;

        const extension = (file.name.split('.').pop() || '').toLowerCase();

        if (extension !== 'csv' && extension !== 'xlsx') {
            return dispatch(errorActions.error(
                null,
                errorMessages.invalidFileType,
                'Invalid file type.'
            ));

        } else if (file.size > constants.gridFileUploadMaximumSize * 1024 * 1024) {
            return dispatch(errorActions.error(
                null,
                errorMessages.fileSizeLimitExceeded(constants.gridFileUploadMaximumSize),
                'The file is too big.'
            ));
        }

        if (position?.editing) {
            dispatch(gridActions.applyOrCancelEdit());
        }

        bidOnBehalfService
            .uploadFile(file, progress)
            .then(success)
            .catch(failure);

        dispatch(gridActions.setUploadState(GridUploadStatus.Uploading, 0, file.name));

        function success(fileUploadResult?: BidOnBehalfFileUploadResultItem[]) {
            const currentMountedKey = getState().grid.mountedKey;

            if (mountedKey === currentMountedKey && fileUploadResult && fileUploadResult?.length) {
                const { securities } = getState().sellerBidding;
                const acceptedTickers = apiUtils.normalize(securities, s => normalizeUppercase(s.ticker), s => s.trade != null);
                const result = fileUploadResult?.filter(x =>
                    x.shortCode?.trim() && // Skip empty company short codes
                    !acceptedTickers[normalizeUppercase(x.ticker)] // Skip traded positions
                ).map(x => {
                    const level = x.level?.trim()
                    return {
                        level: level ? formatUtils.formatBid(Number(level), level) : '',
                        shortCode: normalizeUppercase(x.shortCode),
                        ticker: normalizeUppercase(x.ticker)
                    }
                });

                if (!result?.length) {
                    dispatch(
                        notificationActions.notificationAddErrorMessageModal(
                            "The data was copied incorrectly and could not be parsed by the system. Please copy the data by selecting the Ticker and Broker-Dealer Shortcode columns.",
                            "Incorrect copied data")
                    );
                    dispatch(gridActions.setUploadState(GridUploadStatus.None));
                    return;
                }

                const bidsByTicker: BidOnBehalfFileUploadResultItem[][] = [
                    ...arrayUtils
                        .groupBy(result, item => normalizeUppercase(item.ticker))
                        .values()
                ];

                const acceptedCompanyShortCodes = apiUtils.normalize(
                    getState().bidOnBehalf.companies,
                    c => normalizeUppercase(c.code),
                    () => true
                );
                const companyShortCodes = result.map(x => x.shortCode!);
                const newDataItems: BidOnBehalfParseResultItem[] = bidsByTicker.map(x => ({
                    ticker: x[0].ticker,
                    ...apiUtils.normalize(x, item => item.shortCode ?? '', item => item.level)
                }));

                dispatch(addDataItems(newDataItems, dataItems));
                dispatch(addColumns(
                    companyShortCodes,
                    headers,
                    acceptedCompanyShortCodes,
                    bidOnBehalfUtils.isBidDecreaseDisabled(process),
                    getSecuritiesWithLatestBids({ securities })
                ));
                dispatch(gridActions.validate());
                setTimeout(() => dispatch(gridActions.setUploadState(GridUploadStatus.None)), 500);
            }
        }

        function failure(e: Error) {
            dispatch(gridActions.setUploadState(GridUploadStatus.None));
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                dispatch(errorActions.unexpectedError(e));
            }
        }

        function progress(e: any) {
            const currentMountedKey = getState().grid.mountedKey;
            if (e.lengthComputable && mountedKey === currentMountedKey) {
                var percentComplete = (e.loaded / e.total) * 100;
                dispatch(gridActions.setUploadState(GridUploadStatus.Uploading, percentComplete, file.name));
            }
        }
    }
}

function parseClipboard(text: string) {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const { mountedKey, headers, dataItems } = getState().grid;

        dispatch(gridActions.dataProcessing(true));

        bidOnBehalfService
            .parseClipboard(text)
            .then(success)
            .catch(failure)
            .finally(() => dispatch(gridActions.dataProcessing(false)));

        function success(parseResult: string[][]) {
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey && parseResult?.length) {
                dispatch(applyClipboardParseResult(parseResult, headers, dataItems.filter(i => !i.draft)));
                dispatch(gridActions.validate());
            }
        }

        function failure(e: Error) {
            dispatch(gridActions.setUploadState(GridUploadStatus.None));
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    };
}

function validateDecreaseBid(bidDecreaseDisabled: boolean, securities: BwicPosition[], c: string) {
    return (editingBid: number, dataItem: GridDataItem<BidOnBehalfParseResultItem>) => {
        if (bidDecreaseDisabled) {
            const position = securities.find((s: BwicPosition) => s.ticker.localeCompare(dataItem.ticker as string, undefined, { sensitivity: 'accent' }) === 0);
            if (position) {
                const bid = position.bids?.find((b: Bid) => b.company.code.localeCompare(c, undefined, { sensitivity: 'accent' }) === 0);
                if (bid && Number(editingBid) < bid.value) {
                    return errorMessages.valueCannotBeLess;
                }
            }
        }
        return '';
    }
}

function addColumns(
    companyShortCodes: string[],
    existingColumns: GridColumn[],
    acceptedCompanes: { [companyCode: string]: boolean },
    bidDecreaseDisabled: boolean,
    securities: BwicPosition[]) {
    return (dispatch: TDispatch) => {
        const sanitized = companyShortCodes.filter(c => c?.trim()).map(c => c?.trim());
        const newShortCodeColumns: GridColumn[] = arrayUtils
            .distinct(sanitized, code => code)
            .filter(c => !existingColumns.some(ec => ec.name.localeCompare(c, undefined, { sensitivity: 'accent' }) === 0))
            .map(c =>
                createCompanyBobColumn(
                    c.toUpperCase(),
                    validateDecreaseBid(bidDecreaseDisabled, securities, c),
                    acceptedCompanes[c] == null ? 'Company is not found' : undefined) as GridColumn
            );

        if (newShortCodeColumns.length) {
            const selectBdColumn = existingColumns[existingColumns.length - 1];
            const tickerAndShortCodeColumns = existingColumns.slice(0, -1);

            dispatch(gridActions.setColumns([...tickerAndShortCodeColumns, ...newShortCodeColumns, selectBdColumn]));
        }
    };
}

function addDataItems(dataItems: BidOnBehalfParseResultItem[], existingDataItems: BidOnBehalfParseResultItem[]) {
    return (dispatch: TDispatch) => {
        const isTickerEquals = (a?: string, b?: string) =>
            (a ?? '').trim().localeCompare((b ?? '').trim(), undefined, { sensitivity: 'accent' }) === 0;

        const newItems = dataItems
            .filter(i =>
                values(i).some(v => v) && ( // not empty
                    !i.ticker?.trim() ||
                    !existingDataItems.some(ei => isTickerEquals(ei.ticker, i.ticker))
                )
            );
        const updatedItems = existingDataItems
            .map(ei => {
                const sameTickerItem: BidOnBehalfParseResultItem | undefined = dataItems.find(i => i.ticker && isTickerEquals(i.ticker, ei.ticker));
                return sameTickerItem ? { ...ei, ...sameTickerItem } : ei;
            });
        const items = updatedItems.concat(newItems);

        dispatch(gridActions.clear());
        dispatch(gridActions.addDataItems(items));
    };
}

function normalizeUppercase(value?: string) {
    return value?.trim().toUpperCase() ?? '';
}

function applyClipboardParseResult(
    parseResult: string[][],
    columns: GridColumn[],
    dataItems: GridDataItem<BidOnBehalfParseResultItem>[]) {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const { companies } = getState().bidOnBehalf;
        const { securities } = getState().sellerBidding;
        const { process } = getState().bidding.bwic;

        const acceptedCompanyShortCodes = apiUtils.normalize(companies, c => normalizeUppercase(c.code), () => true);

        if (!parseResult?.length || !companies?.length || !securities.length || !process) return;

        const hasCompanyCodes = parseResult[0]
            .filter(cc => cc)
            .some(cc => acceptedCompanyShortCodes[normalizeUppercase(cc)]);
        const acceptedTickers = apiUtils.normalize(securities, s => normalizeUppercase(s.ticker), s => s.trade != null);
        const hasTickers = parseResult.some(([ticker]) => acceptedTickers[normalizeUppercase(ticker)] != null);

        if (!hasCompanyCodes || !hasTickers) {
            return dispatch(
                notificationActions.notificationAddErrorMessageModal(
                    "The data was copied incorrectly and could not be parsed by the system. Please copy the data by selecting the Ticker and Broker-Dealer Shortcode columns.",
                    "Incorrect copied data")
            );
        }

        const [[, ...companyCodesRaw], ...dataRows] = parseResult.filter(row => row.some(x => x));
        const companyCodes = companyCodesRaw.map(c => c!.trim().toUpperCase());
        const parsedDataItems: BidOnBehalfParseResultItem[] =
            dataRows
                .map(([ticker, ...bids]) => ({
                    ticker: normalizeUppercase(ticker),
                    ...apiUtils.normalize(
                        bids.filter((b, index) => companyCodes[index]?.trim()), // skip bid if company short code is not specified
                        (_, index) => companyCodes[index],
                        bid => acceptedTickers[normalizeUppercase(ticker)] || !bid?.trim() ? '' : formatUtils.formatBid(Number(bid?.trim()), bid?.trim())) // Skip bids if traded
                }));

        dispatch(addDataItems(parsedDataItems, dataItems));
        dispatch(addColumns(
            companyCodes,
            columns,
            acceptedCompanyShortCodes,
            bidOnBehalfUtils.isBidDecreaseDisabled(process),
            getSecuritiesWithLatestBids({ securities })
        ))
    };
}

function addCompany(companyCode: string) {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const headers = getState().grid.headers;
        const { process } = getState().bidding.bwic;
        const { securities } = getState().sellerBidding;

        dispatch(gridActions.addColumn(
            createCompanyBobColumn(companyCode.toUpperCase(), validateDecreaseBid(bidOnBehalfUtils.isBidDecreaseDisabled(process), getSecuritiesWithLatestBids({ securities }), companyCode)),
            headers.length - 1));
        dispatch(gridActions.moveColumnData('selectBd', companyCode));
        dispatch(gridActions.validate());
    };
}

function deleteItemsWithErrors() {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const dataItems: GridDataItem<BidOnBehalfParseResultItem>[] =
            getState().grid.dataItems.filter((x: GridDataItem<BidOnBehalfParseResultItem>) => !x.draft)

        const resetInvalidBids = (dataItem: GridDataItem<BidOnBehalfParseResultItem>): GridDataItem<BidOnBehalfParseResultItem> => {
            const columnsToReset = apiUtils.normalize(dataItem.errors ?? [], e => e.columnName, () => '');
            const clear = {
                ...dataItem,
                ...columnsToReset,
            };

            delete clear['errors'];
            return clear;
        }

        const withoutErrors = dataItems
            .filter(x => !x.errors?.some(e => e.columnName === 'ticker'))
            .map(x => x.errors?.length ? resetInvalidBids(x) : x)
            .map(x => ({ ...x, selectBd: undefined }))

        dispatch(gridActions.clear());
        if (withoutErrors.length) {
            dispatch(gridActions.addDataItems(withoutErrors))
        }
    };
}

function removeUnknownCompanyColumns() {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const columns = getState().grid.headers;
        const companies = getState().bidOnBehalf.companies;

        const acceptedCompanyShortCodes = apiUtils.normalize(
            companies,
            c => c.code?.toUpperCase() ?? '',
            () => true
        );

        columns
            .filter(c => c.name !== 'ticker' && c.name !== 'selectBd' && !acceptedCompanyShortCodes[c.name?.trim()?.toUpperCase()])
            .forEach(c => dispatch(gridActions.removeColumn(c.name)));
    };
}

function saveBids() {
    return (dispatch: TDispatch, getState: () => AppState) => {
        dispatch(gridActions.validate());

        const isValid = getState().grid.isValid;
        if (!isValid) return;

        history.replace({
            ...history.location,
            state: new LocationStateBuilder().resetPopup().result()
        });


        const companies = getState().bidOnBehalf.companies;
        const positions = getState().sellerBidding.securities;

        getState().grid.dataItems
            .filter((i: GridDataItem<BidOnBehalfParseResultItem>) => !i.draft)
            .forEach((i: GridDataItem<BidOnBehalfParseResultItem>) =>
                entries(i)
                    .forEach(([shortCode, bid]) => {
                        const company = companies.find(c => c.code.localeCompare(shortCode, undefined, { sensitivity: 'accent' }) === 0);
                        const position = positions.find(p => p.ticker.localeCompare(i.ticker!, undefined, { sensitivity: 'accent' }) === 0);
                        const originalBid = position?.bids?.find(b => b.company.id === company?.id);

                        if (company && position && bid && (!originalBid || originalBid.value !== Number(bid))) {
                            if (getState().dealerListPanel.companyHiddenState[biddingUtils.getBidCompanyIdentifier({ company })]) {
                                dispatch(dealerListPanelActions.toggleCompanyVisible(biddingUtils.getBidCompanyIdentifier({ company })));
                            }

                            dispatch(bidOnBehalfActions.editBid(position.id, company.id, formatUtils.formatBid(Number(bid)), true));
                        }
                    })
            );
    };
}
