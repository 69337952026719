import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { uploadDocumentsActions } from './upload.documents.actions';
import { AppDispatch } from '../effects/useAppDispatch';
import { AppState } from '../types/state/AppState';

function init() {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const inventories: InventoryPosition[] = getState().entities.inventory.items;
        const securityIds = inventories.map(s => s.securityId);

        dispatch(uploadDocumentsActions.init(securityIds))
    }
}
function reset() {
    return uploadDocumentsActions.reset();
}

export const bdInventoryDocuments = {
    init,
    reset
};
