import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { accountActions as actionTypes, errorMessages, routes } from '../constants';
import {
    allBWICSActions,
    amrPipelineCommonActions,
    bwicHistoryActions,
    companiesActions,
    manageParsedBwicsActions,
    notificationActions,
    portfolioActions,
    profileActions
} from '.';
import { accountService } from '../services';
import { jsonUtils, stringUtils } from '../utils';
import { verifyIdentityActions } from './verify.identity.actions';
import { logger } from '../logging/logger';
import { userProfilePhotoActions } from './user-profile-photo.actions';
import { salesRepresentativeActions } from './sales-representative.actions';
import { bidOnBehalfActions } from './bid-on-behalf.actions';
import { imUserConfigActions } from './im-user-config.actions';
import { resetLiveBiddingDataRequestState } from '../effects/data-accessors/useLiveBiddingData';
import { resetSettlementAgentAgreementsRequestState } from '../effects/useSettlementAgentAgreements';
import { history } from '../history';

export const accountActions = {
    login,
    loginSuccess,
    resetPassword,
    resetPasswordReset,
    setUpPassword,
    passwordValidationFailure,
    setUpPasswordInit,
    setUpPasswordReset,
    changePhoneNumber,
    changePhoneNumberReset,
    changePassword,
    changePasswordReset,
    resetStore,
    refreshAuthentication,
    getFingerprint
};

async function getFingerprint() {
    try {
        const fpPromise = FingerprintJS.load()
        const fp = await fpPromise
        const result = await fp.get()
        const components = { ...result.components };

        delete components.audio;
        delete components.canvas;
        delete components.cookiesEnabled;
        delete components.touchSupport;
        delete components.fontPreferences;
        delete components.screenFrame;
        delete components.screenResolution;

        return FingerprintJS.hashComponents(components);
    } catch (e) {
        logger.exception(e, 'Login: Could not get client fingerprint');
        return ''
    }
}

function login(email, password, pathname = '/') {
    return async dispatch => {
        const fingerprint = await getFingerprint();

        dispatch(loading(true));

        accountService.login(email, password, fingerprint)
            .then(success)
            .catch(error => dispatch(failure(error)))
            .finally(() => dispatch(loading(false)));

        function success(response) {

            if (response.roles) {
                dispatch(loginSuccess(response, pathname));
            } else {
                const { smsAllowed, phoneText } = response;
                dispatch(verifyIdentityActions.verify(email, smsAllowed ? phoneText : undefined, pathname));
            }
        }
    };

    function failure(error) {
        return dispatch => {
            if (error.status === 400) {
                dispatch({ type: actionTypes.LOGIN_FAILURE, error: { errorMessage: errorMessages.signInFailed } });
            } else if (error.status !== 500) {
                dispatch(notificationActions.notificationAddErrorMessage(errorMessages.unexpectedError));
            } else {
                dispatch({ type: actionTypes.LOGIN_FAILURE, error: { errorMessage: errorMessages.unexpectedError } });
            }
        }

    }
}

function loginSuccess(user, pathname) {
    return dispatch => {
        dispatch({ type: actionTypes.LOGIN_SUCCESS, user });
        dispatch(userProfilePhotoActions.fetchUserImage(user.id));
        dispatch(salesRepresentativeActions.loadUserSalesRepresentatives());
        dispatch(amrPipelineCommonActions.registerUser());

        history.replace(pathname || routes.root);
    };
}

function loading(isLoading) {
    return {
        type: actionTypes.LOGIN_LOADING,
        isLoading
    };
}

function resetPassword(email) {
    return dispatch => {
        accountService
            .resetPassword(email)
            .then(success, failure);

        function success() {
            history.replace(routes.forgotPasswordFinished, { isFinished: true });
        }

        function failure() {
            dispatch({
                type: actionTypes.RESET_PASSWORD_FAILURE,
                errorMessage: errorMessages.unexpectedError
            });
        }
    };
}

function resetPasswordReset() {
    return { type: actionTypes.RESET_PASSWORD_RESET };
}

function setUpPasswordInit(email, resetToken) {
    return dispatch => {
        if (stringUtils.isNullOrWhiteSpace(email) ||
            stringUtils.isNullOrWhiteSpace(resetToken)) {
            dispatch(setupPasswordLinkExpired());
        } else {
            dispatch(setuPasswordInitComplete(email, resetToken));
        }
    };
}

function setuPasswordInitComplete(email, resetToken) {
    return {
        type: actionTypes.SETUP_PASSWORD_INIT_COMPLETE,
        email,
        resetToken
    };
}

function setUpPassword(newPassword, confirmPassword) {
    return (dispatch, getState) => {
        const { email, resetToken } = getState().authentication;
        if (stringUtils.isNullOrWhiteSpace(email) ||
            stringUtils.isNullOrWhiteSpace(resetToken)) {
            return dispatch(setUpPasswordFailure({ errorMessage: errorMessages.setupPasswordLinkInvalid }));
        }

        const model = {
            email,
            resetToken,
            newPassword,
            confirmPassword
        };

        accountService
            .setUpPassword(model)
            .then(success, failure);

        function success(isLinkValid) {
            if (isLinkValid) {
                history.replace(routes.setPasswordFinished, { isFinished: true });
            } else {
                dispatch(setupPasswordLinkExpired());
            }
        }

        function failure(e) {
            if (e.message && e.message.length > 0) {
                const json = jsonUtils.tryParse(e.message);
                const errorMessage = json ? formatErrorsFromJson(json) : e.message;
                dispatch(setUpPasswordFailure({ errorMessage }));
            } else {
                dispatch(setUpPasswordFailure({ errorMessage: errorMessages.unexpectedError }));
            }
        }
    };
}

function setupPasswordLinkExpired() {
    return history.replace(routes.setPasswordExpired, { isExpired: true });
}

function passwordValidationFailure(errorMessage) {
    return dispatch => dispatch(setUpPasswordFailure({ errorMessage }));
}

function setUpPasswordFailure(error) {
    return {
        type: actionTypes.SETUP_PASSWORD_FAILURE,
        error
    };
}

function setUpPasswordReset() {
    return { type: actionTypes.SETUP_PASSWORD_RESET };
}

function changePhoneNumber(phone) {
    return (dispatch) => {
        accountService
            .changePhoneNumber(phone)
            .then(success, failure);

        function success() {
            dispatch(profileActions.savePhoneNumber(phone));
            dispatch(profileActions.toggleEditPhoneMode(false));
        }

        function failure(e) {
            if (e.status === 400) {
                const json = jsonUtils.tryParse(e.message);
                const errorMessage = json ? formatErrorsFromJson(json) : e.message;
                dispatch(profileActions.savePhoneNumberFailure(errorMessage));
            } else {
                dispatch(profileActions.savePhoneNumberFailure(errorMessages.unexpectedError));
            }
        }
    };
}

function changePhoneNumberReset() {
    return { type: actionTypes.CHANGE_PHONE_NUMBER_RESET };
}

function changePassword(currentPassword, newPassword, confirmPassword) {
    return async dispatch => {
        const deviceId = await getFingerprint();

        const model = {
            currentPassword,
            newPassword,
            confirmPassword,
            deviceId
        };

        accountService
            .changePassword(model)
            .then(success)
            .catch(failure);

        function success() {
            dispatch({ type: actionTypes.CHANGE_PASSWORD_SUCCESS });
        }

        function failure(e) {
            if (e.status === 400) {
                dispatch({
                    type: actionTypes.CHANGE_PASSWORD_FAILURE,
                    error: { errorMessage: errorMessages.wrongCurrentPassword }
                });
            } else {
                dispatch({
                    type: actionTypes.CHANGE_PASSWORD_FAILURE,
                    error: { errorMessage: errorMessages.unexpectedError }
                });
            }
        }
    };
}

function changePasswordReset() {
    return { type: actionTypes.CHANGE_PASSWORD_RESET };
}

function resetStore() {
    return dispatch => {
        sessionStorage.clear();
        dispatch(allBWICSActions.reset());
        dispatch(manageParsedBwicsActions.reset(true));
        dispatch(portfolioActions.reset(true));
        dispatch(companiesActions.reset());
        dispatch(profileActions.reset());
        dispatch(userProfilePhotoActions.reset());
        dispatch(bidOnBehalfActions.reset());
        dispatch(imUserConfigActions.reset());
        dispatch(bwicHistoryActions.reset());
        dispatch(amrPipelineCommonActions.reset());

        resetLiveBiddingDataRequestState();
        resetSettlementAgentAgreementsRequestState();
    };
}

function refreshAuthentication() {
    return { type: actionTypes.LOGIN_REFRESH_AUTHENTICATION };
}


//TO DO: Code refactoring required
function formatErrorsFromJson(json) {
    const keys = Object.keys(json);

    return keys
        .map(k => json[k].join(' '))
        .join(' ');
}
