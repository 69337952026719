import { createAction } from 'typesafe-actions';
import { ApiOperation } from '../types/api-operation/ApiOperation';
import { ApiOperationResult } from '../types/api-operation/ApiOperationResult';
import { ApiOperationType } from '../types/api-operation/ApiOperationType';

const waitResult = createAction(
    'apiOperation/WAIT_RESULT',
    resolve => (...requests: ApiOperation[]) => resolve({ requests })
);
const result = createAction(
    'apiOperation/RESULT',
    resolve => (result: ApiOperationResult) => resolve({ result })
)
const earlyResult = createAction(
    'apiOperation/EARLY_RESULT',
    resolve => (result: ApiOperationResult) => resolve({ result })
)
const remove = createAction(
    'apiOperation/REMOVE',
    resolve => (token: string) => resolve({ token })
)
const success = createAction(
    'apiOperation/SUCCESS',
    resolve => (result: ApiOperationResult) => resolve({ result })
)
const error = createAction(
    'apiOperation/ERROR',
    resolve => (result: ApiOperationResult) => resolve({ result })
)
const resetEvent = createAction(
    'apiOperation/RESET_EVENT',
    resolve => (event: ApiOperationType) => resolve({ event })
);
const reset = createAction('apiOperation/RESET');
const trackStuckOperations = createAction('apiOperation/TRACK_STUCK_OPERATIONS')

export const apiOperationActions = {
    waitResult,
    result,
    earlyResult,
    success,
    error,
    remove,
    resetEvent,
    reset,
    trackStuckOperations
}
