import { createAction } from "typesafe-actions";
import { ApiError } from "../types/ApiError";
import { PortfolioSlim } from "../types/portfolio/PortfolioSlim";
import { AddToPortfolioPosition } from "../components/portfolio/add-to-portfolio/AddToPortfolioPopup";

const setPopupVisibility = createAction('addToPortfolio/POPUP_VISIBLE',
    resolve => (visible: boolean ) =>
        resolve({ visible })
);

const reset = createAction('addToPortfolio/RESET');

const setSelectedPortfolio = createAction('addToPortfolio/SET_SELECTED_PORTFOLIO',
    resolve => (selectedPortfolio?: PortfolioSlim) => resolve({ selectedPortfolio })
);
const addSecurityToPortfolio = createAction('addToPortfolio/ADD_SECURITY_TO_PORTFOLIO',
    resolve => (position: AddToPortfolioPosition, size: number, newPortfolioName?: string) => resolve({ position, size, newPortfolioName })
);

const setSubmittingProgress = createAction('addToPortfolio/SAVING_PROGRESS',
    resolve => (isSaving: boolean) => resolve({ isSaving })
);

const storeError = createAction('addToPortfolio/ERROR',
    resolve => (error?: ApiError) => resolve({ error })
);

export const addToPortfolioActions = {
    setPopupVisibility,
    addSecurityToPortfolio,
    setSelectedPortfolio,
    setSubmittingProgress,
    storeError,
    reset
};
