import { createAction } from 'typesafe-actions';
import { CompanySlim } from '../types/company/CompanySlim';
import { InventoryPosition } from '../types/inventory/InventoryPosition';

const loadInventory = createAction("entities/admin-inventory-edit/LOAD_INVENTORT",
    resolve => (companyId: number) => resolve({ companyId })
);
const requestInventory = createAction("entities/admin-inventory-edit/REQUEST");
const requestInventorySuccess = createAction("entities/admin-inventory-edit/REQUEST_SUCCESS",
    resolve => (company: CompanySlim | undefined, positions: InventoryPosition[], lockModifiedDate?: Date) =>
        resolve({ company, positions, lockModifiedDate })
);
const requestInventoryError = createAction("entities/admin-inventory-edit/REQUEST_ERROR");
const reset = createAction("entities/admin-inventory-edit/RESET");

export const adminInventoryEditActions = {
    loadInventory,
    requestInventory,
    requestInventorySuccess,
    requestInventoryError,
    reset
};
