import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { CompanySlim } from "../types/company/CompanySlim";

const request = createAction('entities/asset-manager/REQUEST');
const requestResult = createAction('entities/asset-manager/REQUEST_RESULT',
    resolve => (requestState: RequestState, companies: CompanySlim[] = []) =>
        resolve({ requestState, companies })
);

export type TAssetManagerActionType =
    ActionType<
        typeof request |
        typeof requestResult
    >;

export const assetManagerActions = {
    request,
    requestResult
}
