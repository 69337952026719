import { createAction } from "typesafe-actions";
import { TreeSelectOption } from '../components/controls/TreeSelect';
import { TransactionAccessType } from '../types/amr-pipeline/enums/TransactionAccessType';
import {
    TransactionClientActivity,
    TransactionViewHistory,
} from '../types/amr-pipeline/models/TransactionSession';
import { DocumentDownloadsStatistic } from '../types/amr-pipeline/models/DocumentDownloadsStatistic';
import { TransactionVersion } from "../types/amr-pipeline/models/TransactionVersion";
import { TransactionAnalyticsFilter } from '../types/analytics/AnalyticsFilter';

interface AnalyticsInitResponse {
    documentDownloads: DocumentDownloadsStatistic[];
    totalDocumentsDownloads: number;
    recentTransactionViews: number;
    totalTransactionViews: number;
    daysInMarket: number;
    versions: TransactionVersion[];
    clientsActivity: TransactionClientActivity[];
    users: TreeSelectOption<string>[];
}

const analyticsInit = createAction(
    "amrPipelineTransactionAnalytics/INIT",
    (resolve) => (dealReferenceName: string, transactionReferenceName: string) =>
        resolve({ dealReferenceName, transactionReferenceName })
);

const analyticsInitResponse = createAction(
    "amrPipelineTransactionAnalytics/INIT/RESPONSE",
    (resolve) => (analytics: AnalyticsInitResponse) => resolve(analytics)
);

const clientsActivityRequest = createAction(
    'amrPipelineTransactionAnalytics/clientsActivity/REQUEST',
    resolve => (
        dealReferenceName: string,
        transactionReferenceName: string,
        startDate?: Date,
        endDate?: Date,
    ) => resolve({
        dealReferenceName,
        transactionReferenceName,
        startDate,
        endDate,
    })
);

const clientsActivityResponse = createAction(
    'amrPipelineTransactionAnalytics/clientsActivity/RESPONSE',
    resolve => (data: TransactionClientActivity[]) => resolve({ data })
);

const transactionViewHistoryRequest = createAction(
    'amrPipelineTransactionAnalytics/transactionViewHistory/REQUEST',
    resolve => (dealReferenceName: string, transactionReferenceName: string, startDate?: Date, endDate?: Date) =>
        resolve({
            dealReferenceName,
            transactionReferenceName,
            startDate,
            endDate,
        }),
);

const transactionViewHistoryResponse = createAction(
    'amrPipelineTransactionAnalytics/transactionViewHistory/RESPONSE',
    resolve => (data: TransactionViewHistory[]) => resolve({ data }),
);

const clientsActivityFilterChange = createAction(
    'amrPipelineTransactionAnalytics/clientsActivity/FILTER_CHANGE',
    resolve => (filter: TransactionAnalyticsFilter) => resolve(filter),
);

const transactionViewHistoryFilterChange = createAction(
    'amrPipelineTransactionAnalytics/transactionViewHistory/FILTER_CHANGE',
    resolve => (filter: TransactionAnalyticsFilter) => resolve(filter),
);

const transactionViewHistoryFilterByBar = createAction(
    'amrPipelineTransactionAnalytics/transactionViewHistory/FILTER_BY_BAR',
    resolve => (
        dealReferenceName: string,
        transactionReferenceName: string,
        accessType: TransactionAccessType,
        date: Date
    ) => resolve({
        dealReferenceName,
        transactionReferenceName,
        accessType,
        date
    }),
);

const reset = createAction('amrPipelineTransactionAnalytics/RESET');

export const amrTransactionAnalyticsActions = {
    analyticsInit,
    analyticsInitResponse,
    clientsActivityRequest,
    clientsActivityResponse,
    transactionViewHistoryRequest,
    transactionViewHistoryResponse,
    clientsActivityFilterChange,
    transactionViewHistoryFilterChange,
    transactionViewHistoryFilterByBar,
    reset,
};
