import { ActionType, createAction } from 'typesafe-actions';
import { Bank } from '../types/banks/Bank';
import { BankDetailed } from '../types/banks/BankDetailed';
import { ManagersTransactionAggregated } from '../types/banks/ManagersTransactionAggregated';
import { ManagersTransactionCountFilterValues } from '../types/banks/ManagersTransactionCountFilterValues';
import { CompanyEvent } from '../types/banks/CompanyEvent';
import { BankSyndicateContacts } from '../types/banks/BankSyndicateContacts';
import { BrokerDealerSave } from '../types/banks/BrokerDealerSave';
import { RequestState } from '../constants/request-state';
import { BankAccessType } from '../types/amr-pipeline/enums/BankAccessType';
import { BankAnalyticsFilter } from '../types/analytics/AnalyticsFilter';
import { BankClientActivity, BankProfileView } from '../types/amr-pipeline/models/BankSession';
import { TreeSelectOption } from '../components/controls/TreeSelect';
import { BankAnalytics } from '../types/banks/BankAnalytics';
import { ExportManagersTransQueryParams } from '../services/banks.service';

const getBanks = createAction(
    'banks/get/REQUEST',
    resolve => (userCompanyReferenceName?: string) => resolve({ userCompanyReferenceName })
);

const getBanksResult = createAction('banks/get/REQUEST_RESULT',
    resolve => (banks: Bank[]) => resolve({ banks })
);

const getBankDetails = createAction(
    'banks/getOne/REQUEST',
    resolve => (referenceName: string) => resolve({ referenceName }),
);

const getBankDetailsResult = createAction(
    'banks/getOne/REQUEST_RESULT',
    resolve => (
        referenceName: string,
        bankDetails: BankDetailed,
        aggregatedManagerTransactions: ManagersTransactionAggregated[],
        syndicateContacts: BankSyndicateContacts,
        companyEvents: CompanyEvent[],
    ) => resolve({
        referenceName,
        bankDetails,
        aggregatedManagerTransactions,
        syndicateContacts,
        companyEvents,
    }),
);

const managersTransactionCountFilter = createAction(
    'banks/managersTransactionCount/FILTER',
    resolve => (filter: ManagersTransactionCountFilterValues) => resolve(filter),
);

const managersTransactionCountFilterResult = createAction(
    'banks/managersTransactionCount/FILTER_RESULT',
    resolve => (aggregatedManagerTransactions: ManagersTransactionAggregated[]) =>
        resolve(aggregatedManagerTransactions),
);

const exportTransactionCountRequest = createAction(
    'banks/managersTransactionCount/EXPORT',
    resolve => (
        legalName: string,
        referenceName: string,
        params: ExportManagersTransQueryParams
    ) => resolve({legalName, referenceName, params}),
);

const exportTransactionCountResponse = createAction(
    'banks/managersTransactionCount/EXPORT_RESPONSE'
);

const editBank = createAction('banks/EDIT');

const editBankReset = createAction('banks/EDIT_RESET');

const updateBank = createAction(
    'banks/upodate/REQUEST',
    resole => (
        referenceName: string,
        formData: {
            events: CompanyEvent[];
            headOfDesk?: BrokerDealerSave;
            headOfTrading?: BrokerDealerSave;
        }
    ) => resole({
        referenceName,
        formData,
    }),
);

const updateBankResult = createAction(
    'banks/upodate/RESULT',
    resole => (
        requestState: RequestState,
        result?: {
            syndicateContacts: BankSyndicateContacts,
            events: CompanyEvent[]
        }
    ) => resole({
        requestState,
        result,
    }),
);

const setBankForAlertPopup = createAction(
    'banks/alerts/SET_BANK_FOR_ALERT_POPUP',
    resolve => (referenceName?: string) => resolve({ referenceName }),
);

const logUserActivity = createAction(
    'banks/logUserActivity/REQUEST',
    resolve => (companyReferenceName: string, accessType: BankAccessType) =>
        resolve({ companyReferenceName, accessType })
);

const analyticsInit = createAction(
    'banks/analytics/INIT',
    (resolve) => (companyReferenceName: string) => resolve({ companyReferenceName })
);

const analyticsInitResponse = createAction(
    'banks/analytics/INIT/RESPONSE',
    (resolve) =>
        (data: {
            users: TreeSelectOption<string>[];
            analytics: BankAnalytics;
            numberOfHiddenItems: number;
        }) =>
            resolve(data)
);

const analyticsReset = createAction('banks/analytics/RESET');

const clientsActivityRequest = createAction(
    'banks/clientsActivity/REQUEST',
    resolve => (
        companyReferenceName: string,
        startDate?: Date,
        endDate?: Date,
    ) => resolve({
        companyReferenceName,
        startDate,
        endDate,
    })
);

const clientsActivityResponse = createAction(
    'banks/clientsActivity/RESPONSE',
    resolve => (data: BankClientActivity[], numberOfHiddenItems: number) => resolve({ data, numberOfHiddenItems })
);

const clientsActivityFilterChange = createAction(
    'banks/clientsActivity/FILTER_CHANGE',
    resolve => (filter: BankAnalyticsFilter) => resolve(filter),
);

const profileViewHistoryRequest = createAction(
    'banks/profileViewHistory/REQUEST',
    resolve => (companyReferenceName: string, startDate?: Date | null, endDate?: Date | null) =>
        resolve({
            companyReferenceName,
            startDate,
            endDate,
        }),
);

const profileViewHistoryResponse = createAction(
    'banks/profileViewHistory/RESPONSE',
    resolve => (data: BankProfileView[], numberOfHiddenItems: number) => resolve({ data, numberOfHiddenItems }),
);

const profileViewHistoryFilterChange = createAction(
    'banks/profileViewHistory/FILTER_CHANGE',
    resolve => (filter: BankAnalyticsFilter) => resolve(filter),
);

const profileViewHistoryFilterByBar = createAction(
    'banks/profileViewHistory/FILTER_BY_BAR',
    resolve => (
        companyReferenceName: string,
        accessType: BankAccessType,
        date: Date
    ) => resolve({
        companyReferenceName,
        accessType,
        date
    }),
);

const reset = createAction('banks/RESET');


export type TBanksActions = ActionType<typeof banksActions[keyof typeof banksActions]>;

export const banksActions = {
    getBanks,
    getBanksResult,
    getBankDetails,
    getBankDetailsResult,
    managersTransactionCountFilter,
    managersTransactionCountFilterResult,
    exportTransactionCountRequest,
    exportTransactionCountResponse,
    editBank,
    editBankReset,
    updateBank,
    updateBankResult,
    setBankForAlertPopup,
    logUserActivity,
    analyticsInit,
    analyticsInitResponse,
    analyticsReset,
    clientsActivityRequest,
    clientsActivityResponse,
    clientsActivityFilterChange,
    profileViewHistoryRequest,
    profileViewHistoryResponse,
    profileViewHistoryFilterChange,
    profileViewHistoryFilterByBar,
    reset,
};
