import { Dispatch, UnknownAction } from 'redux';
import { AppState } from '../types/state/AppState';
import { amrPipelineService } from '../services/amr-pipeline.service';
import { amrPipelineActions as actionTypes, constants, roles } from '../constants';
import { errorActions } from './error.actions';
import { DealsPipelineFilters } from '../types/state/PipelineFilterState';
import { ThunkDispatch } from 'redux-thunk';
import { user } from '../user';
import { Company } from '../types/amr-pipeline/models/Company';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from 'file-saver';
import { FilterSelectGroup } from '../types/filters/FilterGroup';
import { createSearchTransactionActions } from './search-transactions.actions';
import { PipelineView } from '../components/amrPipeline/types/PipelineView';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import {
    amrPipelineSelector,
    amrPipelineFilterSelector,
    issuanceMonitorFilterSelector,
    summarySelector,
} from '../selectors/amr-pipeline.selector';
import { groupSearchByDealAndClassIndicators, getFilterCriteria, mergeSortCriteria } from '../utils/amr-pipeline.utils';
import { TransactionStatuses, TransactionStatus } from '../types/amr-pipeline/enums/TransactionStatus';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { isNil } from 'lodash';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { queryStringSerializer } from '../utils/filtering';
import { QueryStringArgumentSerializer } from '../utils/filtering/serializers/QueryStringArgumentSerializer';
import {
    savedFilter,
    collateralManagers,
    arrangers,
    dealsLegalNames,
    deals,
    transactionTypes,
    trustees,
    multipleCurrencies,
    reinvestmentEndFrom,
    reinvestmentEndTo,
    nonCallEndFrom,
    nonCallEndTo,
    pricingDateFrom,
    pricingDateTo,
    ratings,
    coupon,
    esg,
    euCompliance,
    staticDeals,
    transactionStatuses,
    amr,
    collateralTypes,
    outOfNC,
    outOfRI,
} from '../utils/filtering/serializers/serializer.definitions';
import { createFilterActions } from './filter.actions';
import { filterUtils } from '../utils/filtering/filter.utils';
import { statsToCalculateWithoutMedians } from '../types/amr-pipeline/enums/StatsToCalculate';
import { TransactionClassResponse, TransactionResponse } from '../types/amr-pipeline/models/TransactionResponse';
import { history } from '../history';
import { amrCompaniesService } from '../services/amr-companies.service';
import { HavingDealType } from '../types/amr-pipeline/enums/HavingDealType';

type TDispatch = ThunkDispatch<AppState, void, UnknownAction>;

export const createAmrPipelineActions = (pipelineType: PipelineType) => {
    const filterActions = createFilterActions(pipelineType);
    const searchTransactionsActions = createSearchTransactionActions(pipelineType);

    function init() {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            try {
                const { initialFilter } = amrPipelineFilterSelector(pipelineType)(getState());

                // load Filters for all tabs
                await dispatch(loadFilters());

                if (!initialFilter) {
                    dispatch(initFilter());

                    const hasImFiltersAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts);

                    if (hasImFiltersAccess) {
                        const {
                            imUserConfig: { filtersConfig },
                        } = getState();

                        let filterType =
                            pipelineType !== PipelineType.ArrangerPipeline
                                ? UserConfigType.imFilter
                                : UserConfigType.apFilter;

                        const selectedFilter = filtersConfig[filterType].value.find(filter => filter.default);
                        dispatch(filterActions.selectFilterFromConfig(selectedFilter, selectedFilter?.referenceName));
                    }

                    await dispatch(setFilterFromSearchString());
                }
                await dispatch(applyFilterAndSearch());
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            }
        };
    }

    function filterExistingOptions(optionsNames: string[], optionFilter: FilterSelectGroup) {
        return optionsNames.filter(o => optionFilter.filter.find(f => f.value === o));
    }

    function setFilterFromSearchString() {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            const { filter } = issuanceMonitorFilterSelector(pipelineType)(getState());
            const {
                imUserConfig: { filtersConfig },
            } = getState();

            const queryString = history.location.search;

            if (!queryString) {
                return;
            }

            // Available for all
            let arrangerReferenceNames: string[] = [];
            let collateralManagerReferenceNames: string[] = [];
            let transactionTypesValues: string[] = [];
            let currenciesValues: string[] = [];
            let statusValues: string[] = [];
            let collateralTypesValues: string[] = [];
            let ratingValues: string[] = [];

            // Only with IM subscription
            let filterReferenceName: string = '';
            let dealsReferenceNames: string[] = [];
            let dealsLegalNamesValues: string[] = [];
            let trusteesReferenceNames: string[] = [];
            let riEndFromValue: string = '';
            let riEndToValue: string = '';
            let ncEndFromValue: string = '';
            let ncEndToValue: string = '';
            let pricingDateFromValue: Date | null = null;
            let pricingDateToValue: Date | null = null;
            let couponValue: string = '';
            let esgValue: boolean | undefined = undefined;
            let euComplianceValue: boolean | undefined = undefined;
            let staticDealValue: boolean | undefined = undefined;
            let isAmrValue: boolean | undefined = undefined;
            let outOfNCValue: boolean | undefined = undefined;
            let outOfRIValue: boolean | undefined = undefined;

            const arrangersSerializer = arrangers(
                arrangerNames => (arrangerReferenceNames = filterExistingOptions(arrangerNames, filter.arrangers)),
            );
            const collateralManagersSerializer = collateralManagers(
                collateralManagers =>
                    (collateralManagerReferenceNames = filterExistingOptions(collateralManagers, filter.managers)),
                    filter.managers.filter.map(filter => filter.value as string)
            );
            const transactionTypesSerializer = transactionTypes(
                transactionTypes => (transactionTypesValues = transactionTypes),
            );
            const currenciesSerializer = multipleCurrencies(currencies => (currenciesValues = currencies));
            const statusSerializer = transactionStatuses(statuses => (statusValues = statuses));
            const collateralTypesSerializer = collateralTypes(types => (collateralTypesValues = types));
            const ratingsSerializer = ratings(ratings => (ratingValues = ratings));
            const filterSerializer = savedFilter(filter => (filterReferenceName = filter));
            const trusteesSerializer = trustees(
                trusteesNames => (trusteesReferenceNames = filterExistingOptions(trusteesNames, filter.trustees)),
            );
            const dealsReferenceNamesSerializer = deals(deals => (dealsReferenceNames = deals));
            const dealsLegalNamesSerializer = dealsLegalNames(deals => (dealsLegalNamesValues = deals));
            const riEndFromSerializer = reinvestmentEndFrom(riEndFrom => (riEndFromValue = riEndFrom));
            const riEndToSerializer = reinvestmentEndTo(riEndTo => (riEndToValue = riEndTo));
            const ncEndFromSerializer = nonCallEndFrom(ncEndFrom => (ncEndFromValue = ncEndFrom));
            const ncEndToSerializer = nonCallEndTo(ncEndTo => (ncEndToValue = ncEndTo));
            const pricingDateFromSerializer = pricingDateFrom(
                pricingDateFrom => (pricingDateFromValue = pricingDateFrom),
            );
            const pricingDateToSerializer = pricingDateTo(pricingDateTo => (pricingDateToValue = pricingDateTo));
            const couponSerializer = coupon(coupon => (couponValue = coupon));
            const esgSerializer = esg(esg => (esgValue = esg));
            const euComplianceSerializer = euCompliance(euCompliance => (euComplianceValue = euCompliance));
            const staticDealsSerializer = staticDeals(staticDeals => (staticDealValue = staticDeals));
            const isAmrSerializer = amr(amr => (isAmrValue = amr));
            const outOfNCSerializer = outOfNC(outOfNC => (outOfNCValue = outOfNC));
            const outOfRISerializer = outOfRI(outOfRI => (outOfRIValue = outOfRI));

            const hasImFiltersAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts);

            const generalSerializers: QueryStringArgumentSerializer<any>[] = [
                collateralManagersSerializer,
                arrangersSerializer,
                transactionTypesSerializer,
                currenciesSerializer,
                ratingsSerializer,
                statusSerializer,
                collateralTypesSerializer,
            ];

            const imFilterSubscriptionSerializers: QueryStringArgumentSerializer<any>[] = [
                filterSerializer,
                dealsReferenceNamesSerializer,
                dealsLegalNamesSerializer,
                trusteesSerializer,
                riEndFromSerializer,
                riEndToSerializer,
                ncEndFromSerializer,
                ncEndToSerializer,
                pricingDateFromSerializer,
                pricingDateToSerializer,
                couponSerializer,
                esgSerializer,
                euComplianceSerializer,
                staticDealsSerializer,
                isAmrSerializer,
                outOfNCSerializer,
                outOfRISerializer,
            ];

            const serializers = hasImFiltersAccess
                ? [...generalSerializers, ...imFilterSubscriptionSerializers]
                : generalSerializers;

            queryStringSerializer.deserialize(queryString, serializers);

            if (filterReferenceName) {
                let filterType =
                    pipelineType !== PipelineType.ArrangerPipeline ? UserConfigType.imFilter : UserConfigType.apFilter;

                const selectedFilter = filtersConfig[filterType].value.find(
                    filter => filter.referenceName === filterReferenceName,
                );

                return dispatch(filterActions.selectFilterFromConfig(selectedFilter, filterReferenceName));
            }

            if (
                arrangerReferenceNames.length ||
                collateralManagerReferenceNames.length ||
                dealsReferenceNames.length ||
                dealsLegalNamesValues.length ||
                transactionTypesValues.length ||
                trusteesReferenceNames.length ||
                currenciesValues.length ||
                riEndFromValue ||
                riEndToValue ||
                ncEndFromValue ||
                ncEndToValue ||
                pricingDateFromValue ||
                pricingDateToValue ||
                ratingValues.length ||
                couponValue ||
                !isNil(esgValue) ||
                !isNil(euComplianceValue) ||
                !isNil(staticDealValue) ||
                !isNil(isAmrValue) ||
                !isNil(outOfNCValue) ||
                !isNil(outOfRIValue) ||
                statusValues.length ||
                collateralTypesValues.length
            ) {
                dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
                dispatch(searchTransactionsActions.reset());
                dispatch(filterActions.resetFiltersVisibility());
            }

            if (trusteesReferenceNames.length) {
                dispatch(filterActions.makeFilterVisible('trustees'));
            }

            if (riEndFromValue || riEndToValue) {
                dispatch(filterActions.makeFilterVisible('reinvestmentEnd'));
                dispatch(filterActions.filterDateSelectOption(dateRangeFilterOptions.YearsRange, 'reinvestmentEnd'));
                dispatch(
                    filterActions.filterDateSelectYearsRange(
                        { from: riEndFromValue, to: riEndToValue },
                        'reinvestmentEnd',
                    ),
                );
            }

            if (ncEndFromValue || ncEndToValue) {
                dispatch(filterActions.makeFilterVisible('nonCallEnd'));
                dispatch(filterActions.filterDateSelectOption(dateRangeFilterOptions.YearsRange, 'nonCallEnd'));
                dispatch(
                    filterActions.filterDateSelectYearsRange({ from: ncEndFromValue, to: ncEndToValue }, 'nonCallEnd'),
                );
            }

            if (pricingDateFromValue || pricingDateToValue) {
                dispatch(filterActions.makeFilterVisible('pricingDate'));
                dispatch(filterActions.filterDateSelectOption(dateRangeFilterOptions.Custom, 'pricingDate'));
                dispatch(
                    filterActions.filterDateSelectCustomRange(
                        { from: pricingDateFromValue, to: pricingDateToValue },
                        'pricingDate',
                    ),
                );
            }

            if (couponValue) {
                dispatch(filterActions.makeFilterVisible('coupons'));
                dispatch(filterActions.filterRadioChange(couponValue, 'coupons'));
            }

            if (!isNil(esgValue)) {
                dispatch(filterActions.makeFilterVisible('esg'));
                dispatch(filterActions.filterRadioChange(esgValue, 'esg'));
            }

            if (!isNil(euComplianceValue)) {
                dispatch(filterActions.makeFilterVisible('euCompliance'));
                dispatch(filterActions.filterRadioChange(euComplianceValue, 'euCompliance'));
            }

            if (!isNil(staticDealValue)) {
                dispatch(filterActions.makeFilterVisible('staticDeal'));
                dispatch(filterActions.filterRadioChange(staticDealValue, 'staticDeal'));
            }

            if (!isNil(isAmrValue)) {
                dispatch(filterActions.makeFilterVisible('amrDeal'));
                dispatch(filterActions.filterRadioChange(isAmrValue, 'amrDeal'));
            }

            if (!isNil(outOfNCValue)) {
                dispatch(filterActions.makeFilterVisible('outOfNC'));
                dispatch(filterActions.filterRadioChange(outOfNCValue, 'outOfNC'));
            }

            if (!isNil(outOfRIValue)) {
                dispatch(filterActions.makeFilterVisible('outOfRI'));
                dispatch(filterActions.filterRadioChange(outOfRIValue, 'outOfRI'));
            }

            arrangerReferenceNames.map(referenceName =>
                dispatch(filterActions.filterSelectChange(referenceName, 'arrangers')),
            );
            trusteesReferenceNames.map(referenceName =>
                dispatch(filterActions.filterSelectChange(referenceName, 'trustees')),
            );

            collateralManagerReferenceNames.map(referenceName =>
                dispatch(filterActions.filterSelectChange(referenceName, 'managers')),
            );

            dealsReferenceNames.map((referenceName, i) =>
                dispatch(
                    searchTransactionsActions.addSearchItem({
                        label: dealsLegalNamesValues[i] ?? referenceName,
                        referenceName,
                    }),
                ),
            );

            transactionTypesValues.map(transactionType =>
                dispatch(filterActions.filterSelectChange(transactionType, 'transactionTypes')),
            );

            currenciesValues.map(currency => dispatch(filterActions.filterSelectChange(currency, 'currency')));

            ratingValues.map(rating => dispatch(filterActions.filterSelectChange(rating, 'ratings')));

            statusValues.map(status => dispatch(filterActions.filterSelectChange(status, 'statuses')));

            collateralTypesValues.map(collateralType =>
                dispatch(filterActions.filterSelectChange(collateralType, 'collateralType')),
            );

            history.replace(history.location.pathname);
        };
    }

    function logInitialAccessToIM() {
        return async (dispatch: TDispatch) => {
            await amrPipelineService.logImUserActivity();

            dispatch({
                type: actionTypes.PIPELINE_INITIAL_LOAD,
                pipelineType,
            });
        };
    }

    function handleVisibilityFilter(filterName: string) {
        return (dispatch: TDispatch, getState: () => AppState) => {
            dispatch(filterActions.filterVisibilityChange(filterName));
            const { filter, lastAppliedFilter } = amrPipelineFilterSelector(pipelineType)(getState());

            const filterChanged = filterUtils.isFilterChanged(pipelineType, filter, lastAppliedFilter);
            const currentFilter = filter[filterName];

            if (!currentFilter.visible) {
                dispatch(filterActions.updateFilterState());
                if (filterChanged) {
                    dispatch(apply());
                }
            }
        };
    }

    function loadFilters() {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            const { initialFilter, redirectFilters } = issuanceMonitorFilterSelector(pipelineType)(getState());

            if (!initialFilter) {
                await Promise.all([
                    dispatch(
                        filterActions.storeFilterData.request({
                            filterName: 'managers',
                            filterType: pipelineType,
                        }),
                    ),
                    dispatch(
                        filterActions.storeFilterData.request({
                            filterName: 'arrangers',
                            filterType: pipelineType,
                        }),
                    ),
                    dispatch(
                        filterActions.storeFilterData.request({
                            filterName: 'classNames',
                            filterType: pipelineType,
                        }),
                    ),
                    dispatch(
                        filterActions.storeFilterData.request({
                            filterName: 'trustees',
                            filterType: pipelineType,
                        }),
                    ),
                ]);

                const [managers, arrangers, classNames, trustees]: [Company[], Company[], string[], Company[]] =
                    await Promise.all([
                        amrCompaniesService.getManagersList(HavingDealType.CmHavingAllPublishedDeals),
                        amrCompaniesService.getArrangersList(true),
                        amrPipelineService.getTransactionClassesNames(),
                        amrCompaniesService.getTrusteeList(),
                    ]);

                await Promise.all([
                    dispatch(
                        filterActions.storeFilterData.success({
                            filterName: 'managers',
                            filterType: pipelineType,
                            data: managers
                                .sort((a, b) => a.legalName.localeCompare(b.legalName))
                                .map(m => ({
                                    selected: false,
                                    disabled: false,
                                    visible: true,
                                    value: m.referenceName,
                                    text: m.legalName,
                                })),
                        }),
                    ),
                    dispatch(
                        filterActions.storeFilterData.success({
                            filterName: 'arrangers',
                            filterType: pipelineType,
                            data: arrangers
                                .sort((a, b) => a.legalName.localeCompare(b.legalName))
                                .map(a => ({
                                    selected: false,
                                    disabled: false,
                                    visible: true,
                                    value: a.referenceName,
                                    text: a.legalName + (a.code ? ' (' + a.code + ')' : ''),
                                })),
                        }),
                    ),
                    dispatch(
                        filterActions.storeFilterData.success({
                            filterName: 'classNames',
                            filterType: pipelineType,
                            data: classNames
                                .sort((a, b) => a.localeCompare(b))
                                .map(
                                    a =>
                                        redirectFilters?.classNames?.find(f => f.value === a) ?? {
                                            selected: false,
                                            disabled: false,
                                            visible: true,
                                            value: a,
                                            text: a,
                                        },
                                ),
                        }),
                    ),
                    dispatch(
                        filterActions.storeFilterData.success({
                            filterName: 'trustees',
                            filterType: pipelineType,
                            data: trustees
                                .sort((a, b) => a.legalName.localeCompare(b.legalName))
                                .map(t => ({
                                    selected: false,
                                    disabled: false,
                                    visible: true,
                                    value: t.referenceName,
                                    text: t.legalName,
                                })),
                        }),
                    ),
                ]);
            }
        };
    }

    function initFilter() {
        return (dispatch: TDispatch, getState: () => AppState) => {
            const { filter } = amrPipelineFilterSelector(pipelineType)(getState());
            const newFilter = { ...filter };

            let statusOptions = TransactionStatuses;

            if (user.hasRoles(...roles.getAllRolesExclude(...roles.admin(), roles.DataEntry))) {
                statusOptions = TransactionStatuses.filter(status => status.value !== TransactionStatus.Draft);
            }

            (newFilter as DealsPipelineFilters).statuses.filter = statusOptions.map(s => ({
                ...s,
                selected: false,
                disabled: false,
                visible: true,
            }));

            dispatch(filterActions.init(newFilter));
            dispatch(filterActions.updateFilterState());
        };
    }

    function apply() {
        return (dispatch: TDispatch) => {
            dispatch(resetTransactions());
            dispatch(applyFilterAndSearch());
        };
    }

    function exportTransactionsClasses(exportedFrom: PipelineView) {
        return (dispatch: Dispatch, getState: () => AppState) => {
            const {
                deals: { classSortBy, classSortDirection, dealSortBy, dealSortDirection },
                searchTransactions: { searchTermItems },
            } = amrPipelineSelector(pipelineType)(getState());

            const { lastAppliedFilter } = amrPipelineFilterSelector(pipelineType)(getState());

            dispatch(setExportLoading(true));

            const { deals, dealsClasses } = groupSearchByDealAndClassIndicators(searchTermItems);

            const exportCriteria = {
                ...getFilterCriteria(lastAppliedFilter, pipelineType),
                deals,
                dealsClasses,
                exportedFrom,
            };

            const classSortCriteria = mergeSortCriteria(
                classSortBy,
                classSortDirection,
                constants.defaultClassOrderCriteria,
            );
            const dealSortCriteria = mergeSortCriteria(
                dealSortBy,
                dealSortDirection,
                constants.defaultDealOrderCriteria,
            );

            amrPipelineService
                .exportTransactionsClasses(exportCriteria, dealSortCriteria, classSortCriteria)
                .then((file: { blob: Blob }) =>
                    saveAs(
                        file.blob,
                        `Issuance Monitor - ${moment().format(constants.issuanceMonitorExportDateFormat)}.xlsx`,
                    ),
                )
                .finally(() => dispatch(setExportLoading(false)));
        };
    }

    function reset() {
        return (dispatch: TDispatch) => {
            dispatch(filterActions.resetFilterState());
            dispatch({
                type: actionTypes.PIPELINE_RESET,
                pipelineType,
            });
        };
    }

    function searchTransactions(isSearching: boolean) {
        return {
            type: actionTypes.PIPELINE_SEARCH_TRANSACTIONS,
            pipelineType,
            isSearching,
        };
    }

    function searchClasses(isSearching: boolean) {
        return {
            type: actionTypes.PIPELINE_SEARCH_CLASSES,
            pipelineType,
            isSearching,
        };
    }

    function dealSortingChanged(sortBy: string, direction: string) {
        return (dispatch: TDispatch) => {
            dispatch({
                type: actionTypes.PIPELINE_DEAL_SORTING_CHANGED,
                pipelineType,
                sortBy,
                direction,
            });
        };
    }

    function classSortingChanged(sortBy: string, direction: string) {
        return (dispatch: TDispatch) => {
            dispatch({
                type: actionTypes.PIPELINE_CLASS_SORTING_CHANGED,
                pipelineType,
                sortBy,
                direction,
            });
        };
    }

    function summaryRequesting(isLoading: boolean) {
        return {
            type: actionTypes.PIPELINE_SUMMARY_REQUESTING_STATE,
            pipelineType,
            isLoading,
        };
    }

    function loadSummary() {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            const {
                filter: { lastAppliedFilter },
                searchTransactions: { searchTermItems },
            } = amrPipelineSelector(pipelineType)(getState());

            const { shouldShowTransactionsSummary, shouldShowClassesSummary } = summarySelector(pipelineType)(getState());

            const filterCriteria = {
                ...getFilterCriteria(lastAppliedFilter, pipelineType),
                deals: searchTermItems.map(i => i.referenceName),
                count: constants.defaultTransactionsSearchCriteria.count,
            };

            dispatch(summaryRequesting(true));

            try {
                const transactionsSummaryPromise = amrPipelineService.getTransactionsSummary(
                    filterCriteria,
                    shouldShowTransactionsSummary
                        ? undefined
                        : statsToCalculateWithoutMedians
                );
                const classesSummaryPromise = shouldShowClassesSummary ? amrPipelineService.getTransactionClassesSummary(filterCriteria) : null;

                const [transactionsSummary, classesSummary] = await Promise.all([
                    transactionsSummaryPromise,
                    classesSummaryPromise,
                ]);

                dispatch({
                    type: actionTypes.PIPELINE_STORE_SUMMARY,
                    pipelineType,
                    transactionsSummary,
                    classesSummary,
                });
            } catch (error) {
                dispatch(errorActions.criticalError(error));
            } finally {
                dispatch(summaryRequesting(false));
            }
        };
    }

    function applyFilterAndSearch() {
        return async (dispatch: TDispatch) => {
            dispatch(filterActions.applyFilter());

            try {
                await Promise.all([
                    dispatch(loadClasses()),
                    dispatch(loadTransactions()),
                    user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess) ? dispatch(loadSummary()) : null,
                ]);
            } catch (e) {
                dispatch(filterActions.resetFilter());
                dispatch(errorActions.criticalError(e));
            }
        };
    }

    function loadTransactions() {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            const {
                deals: { dealSortBy, dealSortDirection, transactionsOffset },
                searchTransactions: { searchTermItems },
            } = amrPipelineSelector(pipelineType)(getState());
            const { lastAppliedFilter } = amrPipelineFilterSelector(pipelineType)(getState());

            const { deals, dealsClasses } = groupSearchByDealAndClassIndicators(searchTermItems);

            const filterCriteria = {
                ...getFilterCriteria(lastAppliedFilter, pipelineType),
                deals,
                dealsClasses,
                count: 40,
            };

            const dealSortCriteria = mergeSortCriteria(
                dealSortBy,
                dealSortDirection,
                constants.defaultDealOrderCriteria,
            );

            dispatch(searchTransactions(true));

            try {
                const transactions: TransactionResponse = await amrPipelineService.getTransactionsList(
                    { ...filterCriteria, offset: transactionsOffset },
                    dealSortCriteria,
                );

                dispatch(searchTransactions(false));
                dispatch({
                    type: actionTypes.PIPELINE_STORE_TRANSACTIONS,
                    pipelineType,
                    transactions,
                });

                if (pipelineType === PipelineType.IOIs && !transactions.items.length) {
                    const oneIOIs: TransactionResponse = await amrPipelineService.getTransactionsList({
                        hasIOIs: true,
                        includeIois: true,
                        count: 1,
                    });

                    const anyIOIsExists = !!oneIOIs.items.length;
                    dispatch({
                        type: actionTypes.PIPELINE_SET_ANY_IOIS_EXISTS,
                        pipelineType,
                        anyIOIsExists,
                    });
                }
            } catch (e) {
                dispatch(searchTransactions(false));
                dispatch(errorActions.criticalError(e));
            }
        };
    }

    function loadClasses() {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            const {
                deals: { classSortBy, classSortDirection, classesOffset },
                searchTransactions: { searchTermItems },
            } = amrPipelineSelector(pipelineType)(getState());
            const { lastAppliedFilter } = amrPipelineFilterSelector(pipelineType)(getState());

            const { deals, dealsClasses } = groupSearchByDealAndClassIndicators(searchTermItems);

            const filterCriteria = {
                ...getFilterCriteria(lastAppliedFilter, pipelineType),
                deals,
                dealsClasses,
                count: 40,
            };

            const classSortCriteria = mergeSortCriteria(
                classSortBy,
                classSortDirection,
                constants.defaultClassOrderCriteria,
            );

            dispatch(searchClasses(true));

            try {
                const classes: TransactionClassResponse = await amrPipelineService.getTransactionClasses(
                    { ...filterCriteria, offset: classesOffset },
                    classSortCriteria,
                );

                dispatch(searchClasses(false));
                dispatch({
                    type: actionTypes.PIPELINE_STORE_CLASSES,
                    pipelineType,
                    classes,
                });
            } catch (e) {
                dispatch(searchClasses(false));
                dispatch(errorActions.criticalError(e));
            }
        };
    }

    function changeFilterSelectFromTable(referenceName: string, filterName: string) {
        return (dispatch: TDispatch, getState: () => AppState) => {
            const { filter } = amrPipelineFilterSelector(pipelineType)(getState());
            const selectedFilterItems = (filter[filterName] as FilterSelectGroup).filter.filter(i => i.selected);
            const isManagerAlreadySelected =
                selectedFilterItems.length === 1 && selectedFilterItems[0].value === referenceName;

            dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
            dispatch(searchTransactionsActions.reset());

            if (!isManagerAlreadySelected) {
                dispatch(filterActions.filterSelectChange(referenceName, filterName));
            }

            dispatch(apply());
        };
    }

    function changeDealSelectFromTable(dealLegalName: string, dealReferenceName: string) {
        return (dispatch: TDispatch, getState: () => AppState) => {
            const {
                searchTransactions: { searchTermItems },
            } = amrPipelineSelector(pipelineType)(getState());
            const isDealSelected =
                searchTermItems.some(i => i.referenceName === dealReferenceName) && searchTermItems.length === 1;

            dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
            dispatch(searchTransactionsActions.reset());

            isDealSelected
                ? dispatch(searchTransactionsActions.removeSearchItem(0))
                : dispatch(
                      searchTransactionsActions.addSearchItem({
                          label: dealLegalName,
                          referenceName: dealReferenceName,
                      }),
                  );

            dispatch(apply());
        };
    }

    function filterReset() {
        return (dispatch: TDispatch) => {
            dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
            dispatch(apply());
        };
    }

    function resetTransactions() {
        return {
            type: actionTypes.PIPELINE_RESET_TRANSACTIONS,
            pipelineType,
        };
    }

    function setExpanded(expanded: boolean) {
        return {
            type: actionTypes.PIPELINE_SET_EXPANDED,
            pipelineType,
            expanded,
        };
    }

    function setExportLoading(isLoading: boolean) {
        return {
            type: actionTypes.PIPELINE_EXPORT_LOAD,
            pipelineType,
            isLoading,
        };
    }

    function setSyndicateContacts(
        dealLegalName: string,
        dealReferenceName: string,
        transactionReferenceName: string,
        arrangerName: string,
    ) {
        return async (dispatch: TDispatch) => {
            try {
                const contacts = await amrPipelineService.getSyndicateContacts(
                    dealReferenceName,
                    transactionReferenceName,
                );

                dispatch({
                    type: actionTypes.PIPELINE_SET_SYNDICATE_CONTACTS,
                    pipelineType,
                    transactionName: dealLegalName,
                    contacts,
                    arrangerName,
                });
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            }
        };
    }

    function resetSyndicateContacts() {
        return {
            type: actionTypes.PIPELINE_SET_SYNDICATE_CONTACTS,
            pipelineType,
            syndicateContacts: null,
        };
    }

    return {
        init,
        reset,
        handleVisibilityFilter,
        filterReset,
        apply,
        searchTransactions,
        resetTransactions,
        dealSortingChanged,
        classSortingChanged,
        setExpanded,
        applyFilterAndSearch,
        exportTransactionsClasses,
        changeFilterSelectFromTable,
        changeDealSelectFromTable,
        loadTransactions,
        loadClasses,
        setSyndicateContacts,
        resetSyndicateContacts,
        logInitialAccessToIM,
        loadFilters,
        initFilter,
    };
};
